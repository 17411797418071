import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, useMediaQuery, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo/LogoBlack.png';
import AppleStoreBadge from '../assets/home/appstore.png';
import GooglePlayBadge from '../assets/home/google.png'; 
import QRApp from '../assets/images/qr-app.png'; 
import { getRatingImageForType } from '../utils/functions';
import soubidScoin from '../assets/images/Soubid-coin.png';
import TestimonialCarousel from '../components/home/TestimonialCarousel';
import Carousel from '../components/home/Carousel';
import Docs from './HomeUserComponent';
import { publicAxios } from '../apiClients';
import ProfileCardSuggestion from '../components/home/ProfileCardSuggestion';
import abogados from '../filesjson/insert_abogados.json';
import NotificationApp from './NotificationApp';
import NotificationQualification from './NotificationQualification';


const HomeUser = () => {
  const { user } = useAuth();
  const [showAppDownload, setShowAppDownload] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [recommendations, setRecommendations] = useState({ received: 0, sent: 0, remaining: 0 });
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [notificationAppModal, setNotificationAppModal] = useState(false);
  const [notificationQualificationModal, setNotificationQualificationModal] = useState(false);

  const getImageSrc = (type, grade) => {
    if (!type || !grade) return null;
    let typeQualification;
    if (type === 'professional') typeQualification = 'qualificationProfessional';
    if (type === 'academic') typeQualification = 'qualificationAcademic';
    if (type === 'student') typeQualification = 'qualificationStudent';
    try {
      return require(`../assets/${typeQualification}/${grade}.png`);
    } catch (error) {
      console.error("Image not found", error);

      return null;
    }
  };

  const [currentImage, setCurrentImage] = useState(require('../assets/images/cal-difuminadoAca.png'));

  useEffect(() => {
    const notificationAppHome = JSON.parse(localStorage.getItem('notificationAppHome'));
    const notificationQualificationHome = JSON.parse(localStorage.getItem('notificationQualificationHome'));
    if (!notificationAppHome?.active && notificationAppHome?.count % 10 === 0 && notificationAppHome?.count !== 0) {
        setNotificationAppModal(true);
    }
    if (!notificationQualificationHome?.active && notificationQualificationHome?.count % 16 === 0 && notificationQualificationHome?.count !== 0) {
        setNotificationQualificationModal(true);
    }

    const newCountApp = notificationAppHome?.count + 1;
    const newCountQualification = notificationQualificationHome?.count + 1;
    localStorage.setItem('notificationAppHome', JSON.stringify({ active: notificationAppHome?.active, count: newCountApp }));
    localStorage.setItem('notificationQualificationHome', JSON.stringify({ active: notificationQualificationHome?.active, count: newCountQualification }));
  }, [user]);

  useEffect(() => {
    const images = [
      require('../assets/images/cal-difuminadoAca.png'),
      require('../assets/images/cal-difuminadoPro.png') // Cambia por la ruta del segundo archivo de imagen
    ];
    let index = 0;

    const intervalId = setInterval(() => {
      index = (index + 1) % images.length;
      setCurrentImage(images[index]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const selectRandomProfiles = (data, num) => {
      if (!data || !data.length) return [];
      const shuffled = data.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    };
  
    if (abogados && Array.isArray(abogados)) {
      const selectedLawyers = selectRandomProfiles(abogados, 5);
      const combinedProfiles = [...selectedLawyers];
  
      const getProfileData = (profile) => {
        const qualification = profile.qualification;
        const qualificationType = qualification ? Object.keys(qualification)[0] : null;
        const qualificationGrade = qualificationType ? qualification[qualificationType] : null;

        const fullName = [
          profile?.name,
          profile?.secondName,
          profile?.lastName,
          profile?.secondLastName
        ].filter(part => part && part !== 'NULL').join(' ');        
        
        let profileData = {
          id: profile.id,
          name: fullName,
          city: profile.city,
          country: profile?.country,
          linkPhoto: profile?.linkPhoto,
          profession: profile.profession,
          university: profile.university,
          company: profile.company,
        };
  
        if (profile.profession === 'Abogado') {
          profileData.rating = getImageSrc(qualificationType, qualificationGrade['grade']);
        } else {
          profileData.rating = getImageSrc(qualificationType, qualificationGrade);
        }
  
        return profileData;
      };
  
      const formattedProfiles = combinedProfiles.map(getProfileData);
      setProfiles(formattedProfiles);
    } else {
      console.error('Error: economistas, medicos or abogados is not an array or is undefined');
    }
  }, [abogados]);  

  const handleChooseDocument = () => {
    navigate('/choose-document', { replace: true });


  };

  const handleQualification = () => {
    navigate('/qualification');
  };

  const fetchRecommendations = async () => {
    try {
        const [receivedRes, sentRes] = await Promise.all([
            publicAxios.get(`/references/all-received?userID=${user.id}`),
            publicAxios.get(`/references/all-sent?userID=${user.id}`),
        ]);
        setRecommendations({
            received: receivedRes.data.count,
            sent: sentRes.data.length,
            remaining: 3 - sentRes.data.length,
        });
    } catch (error) {
        console.error('Error fetching recommendations data:', error);
    }
  };

  useEffect(() => {
    fetchRecommendations();
  }, []);

  const renderRecommendationSection = () => {
    return (
        <>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mt: -1 }}>
                Recomendaciones
            </Typography>
            <Grid container flexDirection='column' justifyContent="center" alignItems="center" spacing={2}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', mb: 2 }}>
                    <Typography sx={{ mx: 1, width: 100, textAlign: 'left' }} variant="body2">Entregadas</Typography>
                    <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', minWidth: 80, backgroundColor: "#F3F3F3" }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#F67147' }}>{recommendations.sent}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', my: 2 }}>
                    <Typography sx={{ mx: 1, width: 100, textAlign: 'left' }} variant="body2">Restantes</Typography>
                    <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', minWidth: 80, backgroundColor: "#F3F3F3" }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#F67147' }}>{recommendations.remaining}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', mt: 2 }}>
                    <Typography sx={{ mx: 1, width: 100, textAlign: 'left' }} variant="body2">Recibidas</Typography>
                    <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', minWidth: 80, backgroundColor: "#F3F3F3" }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#F67147' }}>{recommendations.received}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Button variant="primary" sx={{ width: "30%", alignSelf: 'center', color: '#fff' }} onClick={() => navigate('/recommendations')}>
                Más
            </Button>
        </>
    );
  }

  const renderQualificationSection = () => {
    const qualificationCount = Object.keys(user?.qualification || {}).length;
    if (user?.qualification && qualificationCount !== 0) {
      const imageSize = qualificationCount === 1 ? { height: 250, width: 250 } : { height: 180, width: 180 };
      return (
        <>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            {Object.keys(user?.qualification || {}).map((key, index) => {
              const imageQualification = getRatingImageForType(key, user?.qualification[key].grade);
              const multipleQualifications = Object.keys(user?.qualification || {}).length > 1;
              const adjustedImageSize = multipleQualifications ? { width: '120px', height: '120px' } : imageSize;
              const adjustedFontSize = multipleQualifications ? '0.8rem' : '1rem';
              return (
                <Box key={index} sx={{ mx: 2, textAlign: 'center' }}>
                  <Box sx={{ maxWidth: adjustedImageSize.width, mx: 'auto' }}>
                    <Box
                      component="img"
                      src={imageQualification}
                      alt={`Qualification ${index + 1}`}
                      sx={adjustedImageSize}
                    />
                    <Typography variant="body1" color="black" sx={{ textAlign: 'center', my: 2, maxWidth: adjustedImageSize.width, fontSize: adjustedFontSize }}>
                      Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>{user?.qualification[key]?.percentage || `..`}% más destacado</Typography> entre los abogados calificados en tu país.
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Button variant="primary" onClick={handleQualification} sx={{ width: isMobile ? "40%" : "60%", alignSelf: 'center', color: '#fff' }}>
          Ir a calificarme
        </Button>
        </>
      );
      } else {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, justifyContent: 'space-between', mx: isMobile ? 1 : 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
              <Box
                component="img"
                src={currentImage}
                alt="No Qualification"
                sx={{ height: 290, width: 290, borderRadius: '50%', marginRight: isMobile ? 1 : 0 }}
              />
            </Box>
            <Typography variant="body1" sx={{ textAlign: 'center', padding: 2, color: 'black' }}>
              <Typography component="span" sx={{ fontWeight: 'bold' }}>No tienes calificación</Typography>
              <br />
              <br />
              Descubre cuál es tu calificación como profesional, académico y estudiante
            </Typography>
            <Button variant="primary" onClick={handleQualification} sx={{ width: "30%", alignSelf: 'center', color: '#fff', mt: 2 }}>
              Califícate
            </Button>
          </Box>
        );
      }
  };

  const renderCreditsSection = () => {
    return (
      <>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mt: -1 }}>
          Créditos
        </Typography>
        <Box
          component="img"
          src={soubidScoin}
          alt="Qualification"
          sx={{ height: 80, width: 80, marginRight: isMobile ? 1 : 0, alignSelf: "center", mt: -4 }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", borderRadius: 2, p: 2, mt: -6 }}>
          <Typography variant="body1">
            Tienes en total
          </Typography>
          <Box sx={{ p: 1, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', backgroundColor: "#F3F3F3", width: "50%", alignSelf: 'center', my:2 }}>
            <Typography color="primary" variant="body1" sx={{ fontWeight: 'bold' }}>
              {user.credits}
            </Typography>
          </Box>
          <Typography variant="body1">
            créditos
          </Typography>
        </Box>
        <Button variant="primary" sx={{ width: "30%", alignSelf: 'center', color: '#fff' }} onClick={() => navigate('/credits')}>
          Más
        </Button>
      </>
    );
  }

  return (
    <Box>
      <Box sx={{ backgroundColor: '#F67147', p: isMobile ? 2 : 5 }}>
        <Grid container alignItems="center" justifyContent="center" sx={{ maxWidth: 1200, margin: 'auto' }}>
            <Grid 
                item 
                xs={12} 
                sm={4} 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    mb: isMobile ? 3 : 0 // Espaciado inferior en pantallas pequeñas
                }}
            >
                <Box 
                    component="img" 
                    src={logo} 
                    alt="Logo" 
                    sx={{
                        height: isMobile ? 100 : 150,
                        width: isMobile ? 100 : 150
                    }} 
                />
            </Grid>
            <Grid 
                item 
                xs={12} 
                sm={8} 
                container 
                direction="column" 
                sx={{ 
                    justifyContent: 'center', 
                    alignItems: isMobile ? 'center' : 'flex-start', 
                    textAlign: isMobile ? 'center' : 'left'
                }}
            >
                <Typography 
                    variant="h3" 
                    sx={{ 
                        fontFamily: 'Poppins', 
                        fontWeight: 'bold', 
                        mb: 2, 
                        mt: isMobile ? 1 : 3, // Ajuste de margen superior para pantallas pequeñas
                        fontSize: isMobile ? '1.8rem' : '2.5rem' // Cambia tamaño de fuente en pantallas pequeñas
                    }}
                >
                    Tu marca depende sólo de ti
                </Typography>
                <Typography 
                    variant="body1" 
                    sx={{ 
                        fontFamily: 'Poppins', 
                        mb: 4, 
                        ml: isMobile ? 0 : 1, // Ajusta el margen izquierdo en pantallas pequeñas
                        color: 'black', 
                        maxWidth: isMobile ? '100%' : '80%' // Cambia el ancho máximo del texto
                    }}
                >
                    Soubid ofrece una alternativa a los rankings profesionales, con un modelo basado únicamente en la ciencia. Desde ahora, el desarrollo de tu <strong>marca personal</strong> está en tus manos.
                </Typography>
            </Grid>
        </Grid>
        <TestimonialCarousel />
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        direction={isMobile ? "column" : "row"}
        spacing={isMobile ? 2 : 0}
        marginTop={7}
      >
        {isMobile ? (
          <>
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: isMobile ? 2 : 0 }}>
              <Box sx={{ p: 2, textAlign: 'center', width: '100%', mb: 2, height: 600, display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                {renderQualificationSection()}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mb: isMobile ? 2 : 0 }}>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', width: isMobile ? 350 : '60%', mb: 2, height: 600, display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                {renderRecommendationSection()}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', width: isMobile ? 350 : '60%', mb: 2, height: 600, display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                {renderCreditsSection()}
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', width: '75%', mb: 2, height: 565, display: "flex", flexDirection: "column", justifyContent: "space-around", backgroundColor: "#F3F6FA" }}>
                {renderRecommendationSection()}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ p: 2, textAlign: 'center', width: '100%', mb: 2, height: 600, display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                {renderQualificationSection()}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, textAlign: 'center', width: '75%', mb: 2, height: 565, display: "flex", flexDirection: "column", justifyContent: "space-around", backgroundColor: "#F3F6FA" }}>
                {renderCreditsSection()}
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      {/* <BackgroundComponent /> */}
      <Docs user={user} onClick={handleChooseDocument} />

      {/* Carrusel */}
      <Grid container alignItems="center" justifyContent="center" sx={{ mt: 0 }}>
        <Carousel />
      </Grid>

      <Grid sx={{ alignContent: 'center', alignItems: 'center', backgroundColor: '#000' }}>
        <Typography variant="h3" sx={{ color: '#fff', textAlign: 'center', mt: 5 }}>
          Profesionales Sugeridos
        </Typography>

        <Grid container spacing={2} justifyContent="center" sx={{ pt: 10, pb: 5 }}>
          {profiles.map((profile) => (
            <Grid item xs={12} sm={6} md={4} lg={2.3} key={profile.id}>
              <ProfileCardSuggestion {...profile} /> {/* Abre el modal al hacer clic */}
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
          <Button variant="primary" sx={{ width: "10%", alignSelf: 'center', alignItems: "center", color: '#fff' }} onClick={() => navigate('/directory')}>
            Más
          </Button>
        </Box>
      </Grid>

      {showAppDownload ? (
        <Grid container alignItems="center" justifyContent="center" sx={{ width: '100%', bgcolor: '#F67147' }}>
          <Box container alignItems="center" justifyContent="center" sx={{
            p: 3,
            textAlign: 'center',
            width: '100%',
          }}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={10}>
              <Grid item>
                <Box component="img" src={logo} alt="Logo"
                  sx={{
                    height: 120,
                    width: 120,
                    alignSelf: 'center',
                    mt: 5,
                    mr: 4 // Añade margen derecho para separar más el logo del código QR
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ color: 'black', mb: 2, fontWeight: 'bold', fontFamily: 'Poppins' }}>
                    Descubre nuestra 
                    <br />
                    <span style={{ color: 'black' }}>APP</span>
                </Typography>
                <Box component="img" src={QRApp} alt="QR Code"
                  sx={{
                    height: 100,
                    width: 100,
                    alignSelf: 'center',
                    borderRadius: '5px'
                  }}
                />
              </Grid>
            </Grid>
            {!isMobile ? (
              <></>
            ) : (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
                  <a href="https://apps.apple.com/cl/app/soubid/id6578461746" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={AppleStoreBadge} alt="Download on the App Store" sx={{ height: 40, marginRight: 2 }} />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={GooglePlayBadge} alt="Get it on Google Play" sx={{ height: 40 }} />
                  </a>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      ) : (
        <Divider sx={{ my: 4 }} />
      )}
      {notificationAppModal && <NotificationApp isModalOpen={notificationAppModal} setIsModalOpen={setNotificationAppModal} />}
      {notificationQualificationModal && <NotificationQualification isModalOpen={notificationQualificationModal} setIsModalOpen={setNotificationQualificationModal} />}
    </Box>
  );
};

export default HomeUser;
