import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Button, IconButton, Paper, CircularProgress, useMediaQuery, Grid, Divider } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { userAxios } from '../../apiClients';
import { useSnackbar } from '../snackbar/SnackbarContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomModal from '../CustomModal';
import EmailInputListModal from '../EmailInputListModal';

import logo from '../../assets/logo/LogoBlack.png';

const References = () => {
    const [data, setData] = useState({ received: [], sent: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRecommendation, setSelectedRecommendation] = useState(null);
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const { user } = useAuth();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const id = user?.id;

    const fetchData = async () => {
        try {
            const [referenceReceivedRes, referenceSentRes] = await Promise.all([
                userAxios.get(`/references/all-received?userID=${id}`),
                userAxios.get(`/references/all-sent?userID=${id}`),
            ]);
            setData({
                received: referenceReceivedRes.data,
                sent: referenceSentRes.data,
            });
        } catch (error) {
            console.error('Error fetching recommendations data: ', error);
            showSnackbar('No se pudieron obtener los datos de las recomendaciones', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (recommendationId) => {
        try {
            await userAxios.delete(`/references/${recommendationId}`);
            const updatedSent = data.sent.filter(item => item.id !== recommendationId);
            setData(prevData => ({ ...prevData, sent: updatedSent }));
            setModalVisible(false);
            showSnackbar('Recomendación eliminada exitosamente', 'success');
        } catch (error) {
            console.error('Failed to delete recommendation:', error);
            showSnackbar('Error al eliminar la recomendación', 'error');
        }
    };

    const confirmDelete = (recommendationId) => {
        setSelectedRecommendation(recommendationId);
        setModalVisible(true);
    };

    const handleGoToRecommend = () => {
        navigate('/directory');
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color="primary" />
            </Box>
        );
    }

    return (
        <Box sx={{ padding: isMobile ? 2 : 4 }}>
            <Button
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosNewIcon />}
                sx={{
                    textTransform: 'none',
                    color: 'inherit',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    mb: 2
                }}
            >
                Atrás
            </Button>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, fontFamily: 'Poppins' }}>
                Recomendaciones
            </Typography>
            <Grid container spacing={2} sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EEF1F4', padding: 2, borderRadius: 2, width: '100%' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#F67147' }}>
                            {(data.sent && data.sent.length) || 0}
                        </Typography>
                        <Typography variant="body1">Entregadas</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EEF1F4', padding: 2, borderRadius: 2, width: '100%' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#F67147' }}>
                            {3 - ((data.sent && data.sent.length) || 0)}
                        </Typography>
                        <Typography variant="body1">Restantes</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: '#EEF1F4',
                            padding: 2,
                            borderRadius: 2,
                            width: '100%',  // Asegura que el contenedor tome todo el ancho
                        }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#F67147' }}>
                            {(data.received && data.received.count) || 0}
                        </Typography>
                        <Typography variant="body1">Recibidas</Typography>

                        {(data.received?.qualifications?.betterQualifications?.length > 0 || data.received?.qualifications?.worstQualifications?.length > 0) && (Object.keys(user?.qualification).length !== 0) && (
                            <>
                                <Divider sx={{ width: '100%', my: 1 }} />

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                                    <Typography variant="body2" sx={{ fontSize: '11px', color: 'black' }}>
                                        Las personas que te han recomendado tienen las siguientes calificaciones:
                                    </Typography>

                                    {data.received && data.received.qualifications.betterQualifications.map((option, index) => (
                                        <Box key={`better-${option.qualification}-${index}`} sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                                            <Typography variant="body2" sx={{ fontSize: '11px', color: 'black' }}>
                                                -
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: '#4CAF50', fontWeight: 'bold', pl: 1 }}>
                                                {option.qualification}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: '11px', color: 'black', pl: 1 }}>
                                                ({option.qualificationType})
                                            </Typography>
                                        </Box>
                                    ))}

                                    {data.received && data.received.qualifications.worstQualifications.map((option, index) => (
                                        <Box key={`worst-${option.qualification}-${index}`} sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                                            <Typography variant="body2" sx={{ fontSize: '11px', color: 'black' }}>
                                                -
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: '#A9A9A9', fontWeight: 'bold', pl: 1 }}>
                                                {option.qualification}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: '11px', color: 'black', pl: 1 }}>
                                                ({option.qualificationType})
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>

            </Grid>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, fontFamily: 'Poppins' }}>
                Recomendados
            </Typography>
            <Box>
                {data.sent.map(person => (
                    <Paper key={person.id} sx={{ display: 'flex', alignItems: 'center', padding: 2, mb: 2, backgroundColor: '#EEF1F4' }}>
                        <Avatar src={person.Receiver.linkPhoto} alt={person.Receiver.name} sx={{ width: 70, height: 70, marginRight: 2 }} />
                        <Typography variant="h5" sx={{ flex: 1, fontFamily: 'Poppins' }}>
                            {person.Receiver.fullName}
                        </Typography>
                        <IconButton onClick={() => confirmDelete(person.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Paper>
                ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', mt: 4 }}>
                <Button variant="primary" onClick={handleGoToRecommend} sx={{ backgroundColor: '#F67147', color: 'white', mb: isMobile ? 2 : 0 }}>
                    Ir a recomendar
                </Button>
                <Button variant="secondary" color="secondary" onClick={() => setEmailModalVisible(true)}>
                    Solicitar recomendación
                </Button>
            </Box>

            <CustomModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                onPrimaryAction={() => handleDelete(selectedRecommendation)}
                onSecondaryAction={() => setModalVisible(false)}
                primaryText="Sí"
                secondaryText="Cancelar"
            >
                ¿Estás seguro que deseas retirar esta recomendación? Es posible que ello tenga un impacto en la calificación de la persona.
            </CustomModal>

            <EmailInputListModal
                visible={emailModalVisible}
                onClose={() => setEmailModalVisible(false)}
                userId={id}
            />

            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <img src={logo} alt="logo" style={{ width: '100px' }} />
            </Box>
        </Box>
    );
};

export default References;
