import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Typography, Paper, Button, IconButton, Divider, useMediaQuery, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GppGood, CameraAlt } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import PhotoUploadModal from '../PhotoUploadModal';
import scoin from '../../assets/scoin/scoin.png';
import { userAxios } from '../../apiClients';
import { useSnackbar } from '../snackbar/SnackbarContext';
import Laurel from '../../assets/images/laurel-old-crown.png';
import { getRatingProfessional, getRatingAcademic } from '../../utils/functions';
import soubidUser from '../../assets/images/Soubid-user.png';


function ProfileHeader({ name, ExpertiseArea1, ExpertiseArea2, location, imageUrl, credits, isCurrentUser, userRole, qualification, userSenderID, userReceiverID, publicQualification }) {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [references, setReferences] = useState([]);
  const notAvailable = require('../../assets/images/cal-difuminadoPro.png');
  const [userQualified, setUserQualified] = useState(false);

  const checkUserQualification = () => {
    if (!user) return;
    setUserQualified(Object.keys(user?.qualification).length !== 0);
  };

  useEffect(checkUserQualification, [user]);

  useEffect(() => {
    const fetchReferences = async () => {
      if (!publicQualification) {
        try {
          const response = await userAxios.get(`/references/all-sent?userID=${user?.id}`);
          setReferences(response.data);
        } catch (error) {
          console.error('Error fetching references:', error);
        }
      }
    };

    fetchReferences();
  }, [user?.id]);

  const handleCameraPress = () => {
    setModalVisible(true);
  };

  const handleNavigate = () => {
    if (references.some(ref => ref.Receiver.id === userReceiverID)) {
      showSnackbar('Ya has recomendado a esta persona', 'error');
    } else if (userReceiverID === userSenderID) {
      showSnackbar('No puedes recomendarte a ti mismo', 'error');
    } else {
      const params = {
        name,
        imageUrl,
        userSenderID,
        userReceiverID
      };
      navigate('/reference-user', { state: params });
    }
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
        backgroundColor: '#EEF1F4',
        px: 3,
        mb: 2,
      }}
    >
      <Grid container spacing={isMobile ? 1 : 0} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-start' }} flexDirection={isMobile ? 'column' : 'row'}>
          <Grid item xs={12} md={4}>
            <Box sx={{ position: 'relative' }}>
              <Avatar
                alt={name}
                src={imageUrl || soubidUser}
                sx={{
                  width: isMobile ? '100px' : '150px',
                  height: isMobile ? '100px' : '150px',
                  marginRight: isMobile ? '8px' : '16px',
                  my: isMobile ? '8px' : 3,
                }}
              />
              {isCurrentUser && (
                <IconButton
                  onClick={handleCameraPress}
                  sx={{
                    position: 'absolute',
                    bottom: -5,
                    right: 10,
                    backgroundColor: '#D4DDE4',
                    borderRadius: '50%',
                    width: isMobile ? '35px' : '45px',
                    height: isMobile ? '35px' : '45px',
                    '&:hover': {
                      backgroundColor: '#c0c0c0',
                    }
                  }}
                >
                  <CameraAlt sx={{ color: 'black' }} />
                </IconButton>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={10} sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: theme.palette.gray3, width: "0.7%" }} />
              <Box>
                <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontWeight: 'bold', fontSize: isMobile ? '0.9rem' : '1.1rem' }}>
                  {name}
                  {(userRole === 'verified' || userRole === 'qualified' || userRole === 'leyend') && (
                    <GppGood color="success" sx={{ ml: 1 }} />
                  )}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: isMobile ? '0.6rem' : '0.9rem' }}>
                  Lawyer
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: isMobile ? '0.6rem' : '0.9rem' }}>
                  {ExpertiseArea1} {ExpertiseArea2 ? '/' : ''} {ExpertiseArea2}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ mr: 1, fontFamily: 'Poppins', fontWeight: 'regular', fontSize: isMobile ? '0.6rem' : '0.9rem' }}>
                  {location}
                </Typography>
                {user?.userRole !== 'institutional' && (
                  <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                    {(user && !isCurrentUser && userRole !== 'leyend') && !isMobile && (
                      <Button
                        variant="primary"
                        onClick={handleNavigate}
                        sx={{ backgroundColor: '#F67147', color: 'white', fontSize: isMobile ? '0.6rem' : '0.9rem', mb: 4 }}
                      >
                        Recomendar
                      </Button>
                    )}
                    {(isCurrentUser && userRole !== 'leyend') && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mr: 12 }}>
                        <img src={scoin} alt="créditos" style={{ width: isMobile ? '20px' : '25px', height: isMobile ? '20px' : '25px', marginRight: '5px' }} />
                        <Typography sx={{ fontWeight: 'bold', color: '#F67147', fontSize: isMobile ? '16px' : '20px', fontFamily: 'Poppins' }}>{credits}</Typography>
                      </Box>
                    )}{(isCurrentUser && userRole !== 'leyend') && (
                      <Button
                        component={Link}
                        variant="primary"
                        to="/settings?autoNavigateToProfileInfo=true"
                        sx={{
                          fontWeight: 'bold',
                          color: 'white',
                          fontSize: isMobile ? '8px' : '12px',
                          fontFamily: 'Poppins',
                          textTransform: 'none', // Esto evita que el texto se ponga en mayúsculas
                          py: 0.15, // Esto elimina el padding si quieres que el botón se vea similar al Typography original
                        }}
                      >
                        Editar Perfil
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {user?.userRole !== 'institutional' && (
          <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pl: 0, pr: isMobile ? 0 : 2 }}>
            {publicQualification ? (
              // Si la calificación es pública, muestra la calificación directamente
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: isMobile ? 'column' : 'row', width: '100%', mr: isMobile ? 0 : 2, my: 2 }}>
                {qualification.academic && (
                  <Box sx={{ textAlign: 'center', mx: isMobile ? 0 : 1, mb: isMobile ? 2 : 0 }}>
                    <img
                      src={getRatingAcademic(qualification.academic.grade)}
                      alt="calificación académica"
                      style={{ width: isMobile ? '80px' : '100px', height: isMobile ? '80px' : '100px' }}
                    />
                    {qualification?.academic.grade.includes('A') ? (
                      <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '80px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
                        Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>{qualification.academic?.percentage || `..`}%</Typography> de abogados calificados en el país.
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '80px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
                        Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>1%</Typography> de los abogados de su generación.
                      </Typography>
                    )}
                  </Box>
                )}
                {qualification.professional && (
                  <Box sx={{ textAlign: 'center', mr: isMobile ? 0 : 1, mb: isMobile ? 2 : 0 }}>
                    <img
                      src={getRatingProfessional(qualification.professional.grade)}
                      alt="calificación profesional"
                      style={{ width: isMobile ? '80px' : '100px', height: isMobile ? '80px' : '100px' }}
                    />
                    {qualification?.professional.grade.includes('A') ? (
                      <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '80px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
                        Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>{qualification.professional?.percentage || `..`}%</Typography> de abogados calificados en el país.
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '80px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
                        Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>1%</Typography> de los abogados de su generación.
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            ) : user ? (
              <>
                {(Object.keys(qualification).length !== 0) ? (
                  userRole !== 'leyend' ? (
                    userQualified ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: isMobile ? 'column' : 'row', width: '100%', mr: isMobile ? 0 : 2, my: 2 }}>
                        {(isCurrentUser && qualification.academic) || (qualification.academic && qualification.academic.visible) ? (
                          <Box sx={{ textAlign: 'center', mx: isMobile ? 0 : 1, mb: isMobile ? 2 : 0 }}>
                            <img
                              src={getRatingAcademic(qualification.academic.grade)}
                              alt="calificación académica"
                              style={{ width: isMobile ? '130px' : '100px', height: isMobile ? '130px' : '100px' }}
                            />
                            {qualification?.academic.grade.includes('A') ? (
                              <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '150px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
                                Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>{qualification.academic?.percentage || `..`}%</Typography> de abogados calificados en el país.
                              </Typography>
                            ) : (
                              <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '150px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
                                Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>1%</Typography> de los abogados de su generación.
                              </Typography>
                            )}
                          </Box>
                        ) : null}
                        {(isCurrentUser && qualification.professional) || (qualification.professional && qualification.professional.visible) ? (
                          <Box sx={{ textAlign: 'center', mr: isMobile ? 0 : 1, mb: isMobile ? 2 : 0 }}>
                            <img
                              src={getRatingProfessional(qualification.professional.grade)}
                              alt="calificación profesional"
                              style={{ width: isMobile ? '130px' : '100px', height: isMobile ? '130px' : '100px' }}
                            />
                            {qualification?.professional.grade.includes('A') ? (
                              <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '150px' : '100px', fontSize: isMobile ? '0.7rem' : '0.7rem' }}>
                                Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>{qualification.professional?.percentage || `..`}%</Typography> de abogados calificados en el país.
                              </Typography>
                            ) : (
                              <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '150px' : '100px', fontSize: isMobile ? '0.7rem' : '0.7rem' }}>
                                Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>1%</Typography> de los abogados de su generación.
                              </Typography>
                            )}
                          </Box>
                        ) : null}
                      </Box>
                    ) : (
                      <><Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', ml: isMobile ? 1 : 15 }}>
                        <Tooltip
                          title="Califícate y podrás ver la calificación de este usuario."
                          componentsProps={{
                            tooltip: {
                              sx: {
                                maxWidth: isMobile ? '120px' : '180px', // Ancho según si es móvil
                                fontSize: isMobile ? '10px' : '13px',  // Tamaño de fuente según si es móvil
                                padding: isMobile ? '6px' : '10px',    // Padding según si es móvil
                                whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                                textAlign: 'center',  // Centra el texto en el tooltip
                              },
                            },
                          }}
                        >
                          <img
                            src={notAvailable}
                            alt="professional-rating"
                            style={{ width: isMobile ? '80px' : '170px', height: isMobile ? '80px' : '170px' }} />
                        </Tooltip>
                        <Button variant="primary" color="secondary" sx={{ fontSize: '0.6rem', mminWidth: '80px', height: 23, marginBottom: 6 }} onClick={(e) => navigate('/qualification')}>Califícate </Button>
                      </Box>
                      </>
                    )
                  ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 2 }}>
                      <img src={Laurel} alt="Laurel" style={{ width: isMobile ? 35 : 110, height: 'auto', marginLeft: 5 }} />
                    </Box>
                  )
                ) : (
                  userRole === 'leyend' ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 2 }}>
                      <img src={Laurel} alt="Laurel" style={{ width: isMobile ? 35 : 110, height: 'auto', marginLeft: 5 }} />
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', ml: isMobile ? 1 : 15, mb: isMobile ? 2 : 0 }}>
                      {!userQualified ? (
                        <>
                          <Tooltip
                            title={!isCurrentUser ? "Califícate y podrás ver la calificación de este usuario." : "Califícate y expande tu marca personal."}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  maxWidth: isMobile ? '120px' : '180px', // Ancho según si es móvil
                                  fontSize: isMobile ? '10px' : '13px',  // Tamaño de fuente según si es móvil
                                  padding: isMobile ? '6px' : '10px',    // Padding según si es móvil
                                  whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                                  textAlign: 'center',  // Centra el texto en el tooltip
                                },
                              },
                            }}
                          ><img
                            src={notAvailable}
                            alt="professional-rating"
                            style={{ width: isMobile ? '80px' : '170px', height: isMobile ? '80px' : '170px' }} />
                          </Tooltip>
                          <Typography
                            sx={{
                              fontSize: isMobile ? '12px' : '10px',
                              width: '90%',
                              textAlign: 'center',
                              fontFamily: "Poppins",
                              color: "textSecondary",
                              mt: 1
                            }}
                          >
                            <Button variant="primary" color="secondary" sx={{ fontSize: isMobile ? '0.8rem' : '0.6rem', mminWidth: '80px', height: 23, marginBottom: 6 }} onClick={(e) => navigate('/qualification')}>Califícate </Button>
                          </Typography></>
                      ) : (
                        <><img
                          src={require('../../assets/images/notQualify.png')}
                          alt="professional-rating"
                          style={{ width: isMobile ? '80px' : '100px', height: isMobile ? '80px' : '100px' }} />
                          <Typography
                            sx={{
                              fontSize: isMobile ? '8px' : '10px',
                              width: '90%',
                              textAlign: 'center',
                              fontFamily: "Poppins",
                              color: "textSecondary",
                              mt: 1
                            }}
                          >
                            Usuario aún sin calificación
                          </Typography></>
                      )}
                    </Box>
                  )
                )}
              </>
            ) : null}
          </Grid>
        )}
        {(user && !isCurrentUser && userRole !== 'leyend' && user?.userRole !== 'institutional') && isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 2 }}>
            <Button
              variant="primary"
              onClick={handleNavigate}
              sx={{ backgroundColor: '#F67147', color: 'white', fontSize: isMobile ? '0.8rem' : '1rem', alignSelf: 'flex-end' }}
            >
              Recomendar
            </Button>
          </Box>
        )}
      </Grid>
      {isCurrentUser && <PhotoUploadModal modalVisible={modalVisible} setModalVisible={setModalVisible} />}
    </Paper>
  );
}

export default ProfileHeader;