import React, { useState, useCallback } from 'react';
import {
    Box,
    Typography,
    Button,
    Divider,
    Avatar,
    Grid,
    useMediaQuery,
    Link,
    IconButton
} from '@mui/material';
import BackButton from '../BackButton';
import { userAxios } from '../../apiClients';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import CustomModal from '../CustomModal';
import { AccessTimeRounded, EditNote } from '@mui/icons-material';

const InstructionView = () => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const location = useLocation();
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const { qualificationType } = location.state || {};
    const { showSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [message, setMessage] = useState('');

    const images = getQualificationImages(qualificationType);

    // Función para obtener las imágenes según el tipo de calificación
    function getQualificationImages(type) {
        switch (type) {
            case 'professional':
                return [
                    require('../../assets/qualificationProfessional/B.png'),
                    require('../../assets/qualificationProfessional/A.png'),
                    require('../../assets/qualificationProfessional/C.png'),
                ];
            case 'academic':
                return [
                    require('../../assets/qualificationAcademic/B.png'),
                    require('../../assets/qualificationAcademic/A.png'),
                    require('../../assets/qualificationAcademic/C.png'),
                ];
            case 'student':
                return [
                    require('../../assets/qualificationStudent/B.png'),
                    require('../../assets/qualificationStudent/A.png'),
                    require('../../assets/qualificationStudent/C.png'),
                ];
            default:
                return [
                    require('../../assets/qualificationProfessional/B.png'),
                    require('../../assets/qualificationProfessional/A.png'),
                    require('../../assets/qualificationProfessional/C.png'),
                ];
        }
    }

    // Verifica el tipo de calificación
    const verifyQualification = useCallback(async (type) => {
        try {
            const userRes = await userAxios.get(`/users/profileJSON?userID=${user?.id}`);
            const hasTitle = userRes.data.EducationExperiences.some(exp => exp.educationType === 'title' && exp.state === 'verified');

            if (userRes.data?.Country?.name !== 'Chile') {
                showSnackbar('Estamos trabajando en la calificación para usuarios en tu país', 'info');
                return false;
            }

            if (type === 'student') {
                showSnackbar('Estamos trabajando en la calificación de estudiante, mientras tanto puedes calificarte como profesional', 'info');
                return false;
            }
            if (!hasTitle) {
                showSnackbar(`Debes tener un título verificado para calificarte como ${type}`, 'error');
                return false;
            }
            if (type === 'academic') {
                showSnackbar(`Estamos trabajando en la calificación de académicos, mientras tanto puedes calificarte como profesional`, 'info');
                return false;
            }
            return true;
        } catch (error) {
            showSnackbar('Error al verificar la calificación', 'error');
            console.error(error);
            return false;
        }
    }, [user, showSnackbar]);

    // Revisa si hay experiencias pendientes o precargadas
    const searchForPendings = useCallback(async () => {
        const userRes = user.data;

        const propertiesToCheck = [
            'AcademicExperiences',
            'AcademicScholarships',
            'EducationExperiences',
            'IdiomExperiences',
            'InternationalAccreditations',
            'OtherBackgrounds',
            'ProfessionalExperiences',
            'Publications',
            'RankingNominations',
            'RelevantExperiences',
        ];

        let hasPending = false;
        let hasPreload = false;

        propertiesToCheck.forEach(property => {
            const experiences = userRes[property] || [];
            experiences.forEach(exp => {
                if (exp.state === 'pending') hasPending = true;
                if (exp.state === 'preload') hasPreload = true;
            });
        });
        console.log(hasPending, hasPreload);
        if (hasPending || hasPreload) {
            let messagePart = '';
            if (hasPending && hasPreload) {
                messagePart = (
                    <>
                        <Typography
                            variant="body1"
                            sx={{ fontFamily: 'Poppins', fontSize: 16, mt: 5, textAlign: 'center', justifyContent: 'center' }}
                        >
                            Tienes antecedentes pendientes de verificación y otros pre-cargados que el modelo no tomará en cuenta. En tu perfil puedes ver los antecedentes pendientes con este ícono (
                            <AccessTimeRounded sx={{ color: '#ffd24d', verticalAlign: 'middle', p: 0, mt: 0 }} />
                            ) y los precargados puedes completarlos apretando este ícono (
                            <EditNote sx={{ color: 'gray', verticalAlign: 'middle', p: 0, mt: 0, fontSize: 30 }} />
                            ).
                        </Typography>

                    </>
                );
            } else if (hasPending) {
                messagePart = (
                    <>
                        Tienes antecedentes pendientes de verificación (<AccessTimeRounded sx={{ color: '#ffd24d', verticalAlign: 'middle', p: 0, mt: 0 }} />).
                        El modelo no los tomará en cuenta para tu calificación hasta que se verifiquen.
                    </>
                );
            } else if (hasPreload) {
                messagePart = (
                    <>
                        Tienes antecedentes pre-cargados que aún faltan completar, apretando este ícono (<EditNote sx={{ color: 'gray', verticalAlign: 'middle', p: 0, mt: 0, fontSize: 30 }} />) podrás rellenarlos. 
                        El modelo no los tomará en cuenta para tu calificación hasta que los completes.
                    </>
                );
            }
            setMessage(messagePart);
        }

        return { hasPending, hasPreload };
    }, [user]);

    const handleGoToPay = async () => {
        if (user?.userRole === 'unverified') {
            showSnackbar('Debes verificar tu identidad para poder calificarte', 'error');
            return;
        }
        const { hasPending, hasPreload } = await searchForPendings();
        if (hasPending || hasPreload) {
            setModalVisible(true);
            return;
        }
        handleGoWebPay();
    };

    const handleGoWebPay = async () => {
        try {
            const result = await verifyQualification(qualificationType);
            if (result) {
                const response = await userAxios.post('/webpay/pay', {
                    userID: user?.id,
                    productID: 1,
                    qualificationType
                });
                const { token, url } = response.data;

                // Crear y enviar el formulario dinámicamente
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = url;

                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = 'token_ws';
                hiddenField.value = token;

                form.appendChild(hiddenField);
                document.body.appendChild(form);
                form.submit();
            }
        } catch (error) {
            console.error('Error al iniciar el proceso de pago:', error);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, padding: isMobile ? 1 : 2 }}>
            <Grid container spacing={2} direction="column">
                <Grid item container direction="row" alignItems="center" spacing={2}>
                    <BackButton path={"/qualification"} />
                </Grid>
                <Grid item container alignItems="center" spacing={2} sx={{ ml: isMobile ? 0 : 4, mt: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "2%" }} />
                        <Typography
                            variant="h5"
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 'bold',
                                display: 'inline-flex',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            Calificación {qualificationType.charAt(0).toUpperCase() + qualificationType.slice(1)}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item container direction="column" xs={12} justifyContent="center" alignContent="center">
                    <Grid item xs={12} sx={{ my: 2, width: !isMobile ? '70%' : '100%' }}>
                        <Grid container direction="row" justifyContent="center" spacing={2} sx={{ mb: 4, height: isMobile ? 100 : 'auto' }}>
                            {images.map((image, index) => (
                                <Grid item key={index} alignContent={'flex-end'} sx={{ marginTop: index === 1 ? -10 : 0 }}>
                                    <Avatar
                                        src={image}
                                        sx={{
                                            width: isMobile ? (index === 1 ? 100 : 50) : (index === 1 ? 300 : 200),
                                            height: isMobile ? (index === 1 ? 100 : 50) : (index === 1 ? 300 : 200)
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Typography variant="body1" sx={{ mb: 2, fontFamily: 'Poppins' }}>
                            La calificación es el resultado de una evaluación matemática y objetiva que Soubid realiza de todos los antecedentes académicos y profesionales de sus usuarios.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4, fontFamily: 'Poppins' }}>
                            A continuación podrás conocer tu calificación a partir de los antecedentes que se han verificado. Sin subjetividades ni inclinaciones. Es solo ciencia.
                        </Typography>
                        <Grid container justifyContent="center" spacing={2} sx={{ my: 4 }}>
                            <Button
                                variant="primary"
                                sx={{
                                    backgroundColor: '#F67147',
                                    color: '#fff',
                                    paddingY: 1.5,
                                    paddingX: 3,
                                    borderRadius: 1,
                                    mb: 4,
                                    fontFamily: 'Poppins'
                                }}
                                onClick={handleGoToPay}
                            >
                                Conocer mi Calificación
                            </Button>
                        </Grid>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, fontFamily: 'Poppins' }}>
                            Qué significa cada grado
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#333' }}>
                            Conoce el significado de las calificaciones {' '}
                            <Link component={RouterLink} to="/ratings" sx={{ color: '#F67147', textDecoration: 'none', fontWeight: 'bold' }}>
                                AQUÍ
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <CustomModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                onPrimaryAction={() => handleGoWebPay()}
                onSecondaryAction={() => navigate('/myprofile')}
                primaryText="Si lo sé"
                secondaryText="Ir a mi Perfil"
            >
                {message}
            </CustomModal>
        </Box>
    );
};

export default InstructionView;
