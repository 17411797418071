import React from 'react';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Chip, TextField } from '@mui/material';

const Root = styled('div')`
  width: 100%;
`;

const InputWrapper = styled('div')`
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Listbox = styled('ul')`
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`;

function AutocompleteSelect({ label, options = [], selectedOptions = [], onSelectionChange }) {
  const safeOptions = options || [];
  const safeSelectedOptions = selectedOptions || [];

  // Filtrar opciones duplicadas basado en el id
  const uniqueOptions = Array.from(new Set(safeOptions.map(o => o.id)))
    .map(id => safeOptions.find(o => o.id === id));

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    multiple: true,
    options: uniqueOptions, // Usa opciones únicas
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id, // Comparación personalizada
    value: safeSelectedOptions,
    onChange: (event, newValue) => onSelectionChange(newValue),
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <label {...getInputLabelProps()}>{label}</label>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                key={option.id} // Clave única
                {...tagProps}
                label={option?.name?.length > 20 ? option?.name?.slice(0, 20) + '...' : option?.name}
                deleteIcon={<CloseIcon />}
                onDelete={() => {
                  const newSelectedOptions = safeSelectedOptions.filter(o => o.id !== option.id);
                  onSelectionChange(newSelectedOptions);
                }}
              />
            );
          })}
          <input {...getInputProps()} placeholder="Seleccione..." />
        </InputWrapper>
      </div>
      {groupedOptions?.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => {
            const { key, ...optionProps } = getOptionProps({ option, index });
            return (
              <li key={option.id} {...optionProps}>
                <span>{option.name}</span>
                <CheckIcon fontSize="small" />
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
}

export default AutocompleteSelect;
