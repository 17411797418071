import React from 'react';
import { Box, Typography, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { CssBaseline } from '@mui/material';
import ratings from '../filesjson/ratings.json'; // Asegúrate de ajustar la ruta según tu estructura de proyecto
import BackButton from '../components/BackButton';

function RatingsView() {
    const titleColor = '#F67147'; // Color principal
    const textColor = '#333'; // Color del texto
    const headerBackgroundColor = '#F67147'; // Fondo de los encabezados
    const boxBackgroundColor = '#F5F5F5'; // Fondo de los boxes

    return (
        <>
            <Grid container justifyContent="start" sx={{ m: 1 }}>
                <BackButton path={-1} />
            </Grid>
            <CssBaseline />
            <Container maxWidth="lg" disableGutters sx={{ bgcolor: '#fff', px: 2, pt: 1.5 }}>
                <Typography variant="h3" align="center" gutterBottom sx={{ color: titleColor, fontWeight: 'bold', mb: 4, fontSize: '2.5rem' }}>
                    Calificaciones
                </Typography>
                <Typography variant="h5" align="center" sx={{ color: textColor, mb: 5, maxWidth: '800px', mx: 'auto', fontSize: '1.2rem' }}>
                    Las notas son 45, y van desde <span style={{ color: 'black', fontWeight: 'bold' }}>AAA+</span> (la más alta) hasta <span style={{ color: 'black', fontWeight: 'bold' }}>E-</span> (la más baja). Aquí tienes una descripción detallada de cada una.
                </Typography>
                <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: '8px', bgcolor: boxBackgroundColor, mb: 5 }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ bgcolor: headerBackgroundColor }}>
                                <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#fff' }}>Calificación</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#fff' }}>Descripción</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(ratings).map(([rating, description], index) => (
                                <TableRow key={rating} sx={{ borderBottom: (index + 1) % 9 === 0 ? '2px solid #ccc' : 'none' }}>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#000' }}>{rating}</TableCell>
                                    <TableCell align="left" sx={{ fontSize: '1rem', color: textColor }}>{description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    );
}

export default RatingsView;
