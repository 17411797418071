import React, { useState } from 'react';
import FirmTable from './firmsTable';
import { Box, Container, Typography, Grid, TextField, MenuItem, Select, InputLabel, FormControl, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../styles/StyleTheme';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AppleStoreBadge from '../../assets/home/appstore.png';
import GooglePlayBadge from '../../assets/home/google.png';
import logo from '../../assets/logo/LogoBlack.png';
import QRApp from '../../assets/images/qr-app.png';

const countries = ['US', 'UK', 'Australia', 'Canada']; // Lista de países para el filtro

const MarketMonitor = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [sortBy, setSortBy] = useState({ field: 'position', direction: 'asc' });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleSortChange = (event) => {
        const field = event.target.value;
        setSortBy({ field, direction: 'asc' });
    };

    const handleSort = (field) => {
        const isAsc = sortBy.field === field && sortBy.direction === 'asc';
        setSortBy({ field, direction: isAsc ? 'desc' : 'asc' });
    };

    return (
        <>
        <ThemeProvider theme={theme}>
        <Box
            sx={{
            backgroundColor: theme.palette.gray5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '83vh',
            }}
        >
            <Container sx={{ mt: '30px', mb: 4 }}>
            <Grid container spacing={3} alignItems="center">
                {/* Columna 1: Título */}
                <Grid item xs={3}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black', display: 'flex', alignItems: 'center', ml: '10px' }}>
                    Market Monitor
                    <TrendingUpIcon sx={{ fontSize: '1.5rem', ml: '10px', color: '#F67147' }} />
                </Typography>
                </Grid>

                {/* Columna 2: Búsqueda por firma */}
                <Grid item xs={2.5}>
                <TextField
                    label="Buscar firma..."
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{
                    width: '100%',
                    padding: '8px 0',
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                        height: '33px',
                        '& fieldset': {
                        borderColor: 'white',
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: '#F67147',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: 'black',
                        fontSize: '0.8rem',
                    },
                    }}
                    InputLabelProps={{ style: { color: 'black' } }}
                />
                </Grid>

                {/* Columna 3: Filtro por país */}
                <Grid item xs={2.5}>
                <FormControl fullWidth sx={{ padding: '8px 0' }}>
                    <InputLabel id="country-label" sx={{ color: 'black', fontSize: '0.8rem' }}>Buscar por país</InputLabel>
                    <Select
                    labelId="country-label"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    label="Buscar por país"
                    sx={{
                        backgroundColor: 'white',
                        height: '33px',
                        '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#F67147',
                        },
                    }}
                    >
                    <MenuItem value="">Todos</MenuItem> {/* Opción para seleccionar todos los países */}
                    {countries.map((country) => (
                        <MenuItem key={country} value={country}>
                        {country}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid>

                {/* Columna 4: Ordenar por */}
                <Grid item xs={2.5}>
                <FormControl fullWidth sx={{ padding: '8px 0' }}>
                    <InputLabel id="sort-label" sx={{ color: 'black', fontSize: '0.8rem' }}>Ordenar por</InputLabel>
                    <Select
                    labelId="sort-label"
                    value={sortBy.field}
                    onChange={handleSortChange}
                    label="Ordenar por"
                    sx={{
                        backgroundColor: 'white',
                        height: '33px',
                        '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#F67147',
                        },
                    }}
                    >
                    <MenuItem value="position">Posición</MenuItem>
                    <MenuItem value="name">Nombre</MenuItem>
                    <MenuItem value="country">País</MenuItem>
                    <MenuItem value="totalScore">Puntaje</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
            </Grid>
            </Container>

            <Container sx={{ flexGrow: 1 }}>
                <FirmTable searchTerm={searchTerm} selectedCountry={selectedCountry} sortBy={sortBy} handleSort={handleSort} />
            </Container>
        </Box>
        </ThemeProvider>

        {/* Descarga de la APP */}
        <Grid container alignItems="center" justifyContent="center" sx={{ width: '100%', bgcolor: '#F67147' }}>
            <Box container alignItems="center" justifyContent="center" sx={{
                p: 2,
                textAlign: 'center',
                width: '100%',
            }}>
            <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={10}>
                <Grid item>
                <Box component="img" src={logo} alt="Logo"
                    sx={{
                    height: 100,
                    width: 100,
                    alignSelf: 'center',
                    mt: 4,
                    mr: 3
                    }}
                />
                </Grid>
                <Grid item>
                <Typography variant="body1" sx={{ color: 'white', mb: 2, fontWeight: 'bold' }}>
                    Conoce nuestra APP
                </Typography>
                <Box component="img" src={QRApp} alt="QR Code"
                    sx={{
                    height: 100,
                    width: 100,
                    alignSelf: 'center',
                    borderRadius: '5px',
                    }}
                />
                </Grid>
            </Grid>
            {isMobile && (
                <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
                    <a href="https://apps.apple.com/cl/app/soubid/id6578461746" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={AppleStoreBadge} alt="Download on the App Store" sx={{ height: 40, marginRight: 2 }} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={GooglePlayBadge} alt="Get it on Google Play" sx={{ height: 40 }} />
                    </a>
                </Box>
                </>
            )}
            </Box>
        </Grid>
        </>
    );
};

export default MarketMonitor;
