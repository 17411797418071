import React from 'react';
import { Grid, Box, Avatar, Button, Typography, Divider, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { checkUserBackground } from '../../utils/functions';
import { useState, useEffect } from 'react';
import AppleStoreBadge from '../../assets/home/appstore.png';
import GooglePlayBadge from '../../assets/home/google.png';
import logo from '../../assets/logo/LogoBlack.png';
import QRApp from '../../assets/images/qr-app.png';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import Loading from '../../components/Loading';
import { userAxios } from '../../apiClients';
import BackButton from '../../components/BackButton';
import soubidUser from '../../assets/images/Soubid-user.png';

const navigateToUpload = (navigate, type) => {
  const routes = {
    'Pregrado': { path: '/upload-education', params: { title: 'Pregrado', description: 'Carga el diploma o certificado que acredite tu grado de licenciado. Este antecedente será sometido a nuestro sistema de verificación cruzada.', degree: 1 } },
    'Título profesional': { path: '/upload-professional-title', params: { title: 'Título profesional', description: 'Debes acompañar una copia del título de abogado(a) emitido por la Corte Suprema. También podrás cargar un certificado de abogado emitido por el Poder Judicial.', degree: 4 } },
    'Postgrado': { path: '/upload-education', params: { title: 'Postgrado', description: 'Cargar el diploma o certificado que acredite tu grado de magíster o doctor (Phd). Este antecedente será sometido a nuestro sistema de verificación cruzada.', degree: 2 } },
    'Cursos y diplomas': { path: '/upload-education', params: { title: 'Cursos y diplomas', description: 'Acompaña el certificado respectivo, y lo someteremos a verificación cruzada. El valor de los cursos online es inferior al de aquellos realizados de forma presencial.', degree: 3 } },
    'Docencia pasada': { path: '/upload-academic', params: { title: 'Docencia pasada', description: 'Debes acompañar algún documento que demuestre tu actividad docente (un contrato, por ejemplo), o copiar algún link oficial de la institución en la que enseñas. Este antecedente será sometido a nuestro sistema de verificación cruzada.', previus: true } },
    'Docencia actual': { path: '/upload-academic', params: { title: 'Docencia actual', description: 'Debes acompañar algún documento que demuestre tu actividad docente (un contrato, por ejemplo), o copiar algún link oficial de la institución en la que enseñas. Este antecedente será sometido a nuestro sistema de verificación cruzada.', previus: false } },
    'Publicaciones': { path: '/upload-publication', params: { title: 'Publicaciones', description: 'Para demostrar tu autoría de un artículo o de un libro, no es necesario que adjuntes el documento. Basta con que nos indiques algún link en el que se encuentre disponible la publicación (por ejemplo, Scielo), o el sitio web de alguna librería o biblioteca.' } },
    'Becas': { path: '/upload-scholarship', params: { title: 'Becas académicas', description: 'Si has obtenido alguna beca en reconocimiento a tu buen desempeño académico y/o liderazgo, demuéstralo acompañando documentación oficial, o con algún link oficial de la institución que haya otorgado la beca. Las becas socioeconómicas no son consideradas en la calificación.' } },
    'Habilitación internacional': { path: '/upload-int-accreditation', params: { title: 'Habilitación internacional', description: 'Si estás habilitado para ejercer la profesión en un lugar distinto de Chile (por ejemplo, si aprobaste el BAR Exam en algún Estado de Estados Unidos), demuéstralo acompañando el certificado respectivo, o algún link oficial de la institución examinadora.' } },
    'Idiomas': { path: '/upload-idiom', params: { title: 'Idiomas', description: 'El manejo de otro idioma debe demostrarse con certificaciones oficiales, o con algún link de la institución examinadora.' } },
    'Puesto actual': { path: '/upload-professional', params: { title: 'Puesto de trabajo actual', description: 'Debes acompañar algún documento que demuestre tu lugar de trabajo (contrato de trabajo, por ejemplo), o copiar algún link oficial de la institución. Este antecedente será sometido a nuestro sistema de verificación cruzada.', previus: false } },
    'Puesto anterior': { path: '/upload-professional', params: { title: 'Puesto de trabajo anterior', description: 'Debes acompañar algún documento que demuestre tu lugar de trabajo (contrato de trabajo, por ejemplo), o copiar algún link oficial de la institución. Este antecedente será sometido a nuestro sistema de verificación cruzada. Mientras más cargos subas, más posibilidades tienes de subir tu calificación.', previus: true } },
    'Rankings internacionales': { path: '/upload-ranking', params: { title: 'Rankings internacionales', description: 'Si estás rankeado personalmente por Chambers & Partners, The Legal 500, Who is Who Legal, o Leaders League, copia el link en que apareces mencionado.' } },
    'Experiencias relevantes': { path: '/upload-relevant-experience', params: { title: 'Experiencias relevantes', description: 'Carga los asuntos relevantes en los que has participado, acompañando algún documento (un escrito judicial o una escritura pública, por ejemplo), o copiando el link de algún medio de comunicación serio que te mencione expresamente. Linkedin no es prueba suficiente.' } },
    'Otros antecedentes': { path: '/upload-other-background', params: { title: 'Otros antecedentes', description: 'Si cuentas con otros antecedentes profesionales o académicos relevantes, que no se encuentran mencionados en el listado anterior, puedes incorporarlos aquí (por ejemplo, ser o haber sido abogado integrante, o árbitro). Ten en cuenta que no todos los antecedentes influyen en tu calificación.' } },
  };

  if (routes[type]) {
    const { path, params } = routes[type];
    navigate(path, { state: params });
  }
};

function UploadDocument() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { user } = useAuth();
  const [profile, setProfile] = useState({});
  const [userBackground, setUserBackground] = useState({});
  const [showAppDownload, setShowAppDownload] = useState(true);
  const [trueCounter, setTrueCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'institutional' && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  const typeToKeyMap = {
    'Pregrado': 'undergraduate',
    'Postgrado': ['magister', 'doctorate'],
    'Cursos y diplomas': ['diplomat', 'course'],
    'Becas': 'academicScholarships',
    'Docencia pasada': 'pastTeaching',
    'Docencia actual': 'currentTeaching',
    'Publicaciones': 'publications',
    'Habilitación internacional': 'internationalAccreditations',
    'Idiomas': 'idiomExperiences',
    'Título profesional': 'title',
    'Puesto actual': 'currentProfessional',
    'Puesto anterior': 'pastProfessional',
    'Experiencias relevantes': 'relevantExperiences',
    'Rankings internacionales': 'rankingNominations',
    'Otros antecedentes': 'otherBackground',
  };


  useEffect(() => {
    const fetchData = async () => {
      if (userProfileId) {
        const response = await userAxios.get(`/users/profileJSON?userID=${userProfileId}`);
        const userBackground = checkUserBackground(response.data);
        setUserBackground(userBackground);
        const countTrueTypes = () => {
          return Object.keys(typeToKeyMap).reduce((count, type) => {
            const keys = typeToKeyMap[type];
            if (Array.isArray(keys)) {
              if (keys.some(key => userBackground[key])) {
                return count + 1;
              }
            } else {
              if (userBackground[keys]) {
                return count + 1;
              }
            }
            return count;
          }, 0);
        };
        setTrueCounter(countTrueTypes());
        setProfile(response.data);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userProfileId]);

  // if (user) {
  //   userBackground = checkUserBackground(user.data);
  // }

  let color;
  if (trueCounter < 5) {
    color = 'red';
  } else if (trueCounter > 10) {
    color = 'green';
  } else {
    color = 'inherit';
  }

  const isTypeTrue = (type) => {
    const keys = typeToKeyMap[type];
    if (Array.isArray(keys)) {
      return keys.some(key => userBackground[key]);
    }
    return userBackground[keys];
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {user && user?.userRole === 'institutional' && (
        <Box sx={{ display: 'flex-start', justifyContent: 'center', mt: 1, ml: 1 }}>
          <BackButton back={true} />
        </Box>
      )}
      <Grid container spacing={1} direction="column">
        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'flex-start',
          border: '1px solid lightgray',
          borderRadius: '5px',
          padding: '40px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#ffffff',
          maxWidth: '1000px',
          margin: 'auto',
          mt: 4
        }}>
          {Object.keys(profile).length !== 0 && (
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <Avatar
                  src={profile.linkPhoto || soubidUser}
                  sx={{ width: 150, height: 150 }}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 3 }}>
                  <Divider orientation="vertical" flexItem sx={{ height: 32, mr: 2, bgcolor: "black", width: "4px" }} />
                  <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                    {profile.fullName}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', color: 'gray', mt: -1, ml: '26px' }}>
                  {profile.City?.name}, {profile.Country?.name}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "flex-end", ml: 2, mt: 4, textAlign: 'center', mt: isMobile ? 5 : 0 }}>
                <BackupOutlinedIcon sx={{ width: 50, height: 50 }} />
                <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', my: 1 }}>
                  Llevas <span style={{ fontWeight: 'bold', color }}>{trueCounter}</span> de 15 categorías posibles
                </Typography>
                {(user?.userRole !== 'queue' && user?.userRole !== 'institutional') && (
                  <Button variant="primary" color="secondary" sx={{ fontSize: '1rem', mminWidth: '100px', height: 30 }} onClick={() => navigate('/qualification')}>
                    Califícate
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Grid container spacing={1} direction={isMobile ? "column" : "row"} sx={{ mt: 3 }}>

          <Grid item container xs={6} justifyContent={"center"} sx={{ mt: 5 }}>
            <Grid item xs={11} sx={{ display: 'flex', alignItems: 'center' }}>
              <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
              <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', ml: '5px', height: 65, maxHeight: 65 }}>
                Carga tus antecedentes académicos
              </Typography>
            </Grid>
            <Grid item container spacing={3} xs={11} justifyContent={"center"} sx={{
              padding: 2,
              flexDirection: 'row',
              maxWidth: '750px',
              margin: '0 auto',
            }}>
              {['Pregrado', 'Postgrado', 'Cursos y diplomas', 'Becas', 'Docencia pasada', 'Docencia actual', 'Publicaciones', 'Habilitación internacional', 'Idiomas'].map((type) => (
                <Grid item xs={12} sm={6} key={type} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                  <Button
                    variant={isTypeTrue(type) ? 'primary' : 'contained'}
                    color={isTypeTrue(type) ? 'primary' : 'secondary'}
                    onClick={() => navigateToUpload(navigate, type)}
                    sx={{
                      minWidth: '100%',
                      padding: '12px 20px',
                      height: '100%',
                      maxHeight: '50px'
                    }}
                  >
                    {type}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item container xs={6} justifyContent={"center"} sx={{ mt: 5, mb: 1 }}>
            <Grid item xs={11} sx={{ display: 'flex', alignItems: 'center' }}>
              <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
              <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', ml: '5px', height: 40, maxHeight: 40, mb: 3 }}>
                Carga tus experiencias profesionales
              </Typography>
            </Grid>
            <Grid item container spacing={3} xs={11} justifyContent={"center"} sx={{
              padding: 2,
              flexDirection: 'row',
              maxWidth: '750px',
              margin: '0 auto',
            }}>
              {['Título profesional', 'Experiencias relevantes', 'Puesto anterior', 'Puesto actual', 'Rankings internacionales', 'Otros antecedentes'].map((type) => (
                <Grid item xs={12} sm={6} key={type} sx={{ justifyContent: 'center', marginBottom: '10px' }}>
                  <Button
                    variant={isTypeTrue(type) ? 'primary' : 'contained'}
                    color={isTypeTrue(type) ? 'primary' : 'secondary'}
                    onClick={() => navigateToUpload(navigate, type)}
                    sx={{
                      minWidth: '100%',
                      padding: '12px 20px',
                      height: '100%',
                      maxHeight: '50px'
                    }}
                  >
                    {type}
                  </Button>
                </Grid>
              ))}
              <Divider sx={{ mb: 30 }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
          <Typography variant="body1" sx={{ textAlign: 'center', color: 'dark', maxWidth: 800, mx: isMobile ? 2 : 'auto', mb: 5 }}>
            * Al cargar tus antecedentes autorizas a Soubid a almacenarlos y a utilizarlos para determinar tu calificación. También aceptas que ellos sean visualizados por otros usuarios.
          </Typography>
        </Grid>
      </Grid>

      {showAppDownload ? (
        <Grid container alignItems="center" justifyContent="center" sx={{ width: '100%', bgcolor: '#F67147' }}>
          <Box container alignItems="center" justifyContent="center" sx={{
            p: 2,
            textAlign: 'center',
            width: '100%',
          }}>
            <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={10}>
              <Grid item>
                <Box component="img" src={logo} alt="Logo"
                  sx={{
                    height: 100,
                    width: 100,
                    alignSelf: 'center',
                    mt: 4,
                    mr: 3
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ color: 'white', mb: 2, fontWeight: 'bold' }}>
                  Conoce nuestra APP
                </Typography>
                <Box component="img" src={QRApp} alt="QR Code"
                  sx={{
                    height: 100,
                    width: 100,
                    alignSelf: 'center',
                    borderRadius: '5px',
                  }}
                />
              </Grid>
            </Grid>
            {isMobile && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
                  <a href="https://apps.apple.com/cl/app/soubid/id6578461746" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={AppleStoreBadge} alt="Download on the App Store" sx={{ height: 40, marginRight: 2 }} />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={GooglePlayBadge} alt="Get it on Google Play" sx={{ height: 40 }} />
                  </a>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      ) : (
        <Divider sx={{ my: 4 }} />
      )}
    </>
  );
}

export default UploadDocument;