import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import soubidUser from '../../assets/images/Soubid-user.png';

const CustomCard = styled(Card)(({ theme }) => ({
  maxWidth: 300,
  borderRadius: '16px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  margin: 'auto',
}));

const ProfileImage = styled(CardMedia)({
  borderRadius: '50%',
  width: 150,
  height: 150,
  margin: '0 auto',
});

const Divider = styled(Box)({
  width: '80%',
  height: '2px',
  backgroundColor: '#E0E0E0',
  margin: '20px auto',
});

const RatingBadge = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: 80,
  height: 80,
  margin: '20px auto',
  color: '#F67147',
  fontSize: '24px',
  fontWeight: 'bold',
});

const ProfileButton = styled(Button)({
  backgroundColor: '#F67147',
  color: '#FFFFFF',
  padding: '10px 20px',
  borderRadius: '24px',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#F67147',
  },
});

const NameTypography = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  height: '3em', // Adjust based on the line height and desired number of lines
  lineHeight: '1.5em', // Adjust based on the desired line height
  marginBottom: '10px', // Adjust spacing as needed
});

function ProfileCardSuggestion({ id, name, university, profession, city, country, linkPhoto, rating, onClick }) {

  const handleImageError = (event) => {
    event.target.src = soubidUser; // Reemplaza la imagen con la imagen por defecto en caso de error
  };

  return (
    <CustomCard>
      <ProfileImage component="img" image={linkPhoto ? linkPhoto : soubidUser} alt={name} onError={handleImageError} />
      <CardContent>
        <NameTypography variant="h5" component="div" gutterBottom>
          {name}
        </NameTypography>
        <Divider />
        <Typography variant="body1" color="textPrimary">
          {profession}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {country ? country: '' }
        </Typography>
        <RatingBadge>
          <img src={rating} alt="professional-rating" style={{ width: '100px', height: '100px' }} />
        </RatingBadge>
        <ProfileButton 
          variant="primary" 
          component={Link}
          to={`/profile/${id}`} 
        >
          Ver Perfil
        </ProfileButton>
      </CardContent>
    </CustomCard>
  );
}

export default ProfileCardSuggestion;
