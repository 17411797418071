// BalanceRadarChartView.js
import React, { useState } from 'react';
import BalanceRadarChart from './balanceRadarChart';
import { useTheme, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

// Importar la escala radial personalizada
import CustomRadialScale from './customRadialScale';

// Función auxiliar para convertir hex a rgba
const hexToRgba = (hex, alpha) => {
    const hexValue = hex.replace('#', '');
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const MarketComparative = () => {
const theme = useTheme();

// Estado para el select interactivo
const [selectedFirmas, setSelectedFirmas] = useState([]);

// Valores de las firmas
const firmas = {
    'Firma 1': [65, 59, 90, 81, 56, 55, 55, 70],
    'Firma 2': [80, 72, 95, 68, 60, 77, 48, 82],
    // Agrega más firmas según sea necesario
};

const primaryColor = '#F67147';
const secondaryColor = '#47A6F6'; // Color para la segunda firma

// Mapa de colores para las firmas
const firmColors = {
    'Firma 1': primaryColor,
    'Firma 2': secondaryColor,
    // Agrega más firmas y sus colores según sea necesario
};

// Generar datasets para todas las firmas seleccionadas
const datasets = selectedFirmas.map((firma) => {
    const dataValues = firmas[firma];
    const color = firmColors[firma] || '#888888'; // Color por defecto si no está especificado

    return {
    label: firma,
    data: dataValues,
    fill: true,
    borderColor: color,
    borderWidth: 1,
    pointRadius: 0,
    pointHoverRadius: 0,
    backgroundColor: (ctx) => {
        const { chartArea, ctx: context } = ctx.chart;
        if (!chartArea) return;

        const gradient = context.createRadialGradient(
        chartArea.width / 2,
        chartArea.height / 2,
        0,
        chartArea.width / 2,
        chartArea.height / 2,
        chartArea.width / 2
        );

        const colorStart = hexToRgba(color, 0.2);
        const colorMid = hexToRgba(color, 0.5);
        const colorEnd = hexToRgba(color, 0.8);

        gradient.addColorStop(0, colorStart);
        gradient.addColorStop(0.5, colorMid);
        gradient.addColorStop(1, colorEnd);

        return gradient;
    },
    };
});

const data = {
    labels: [
    'Team',
    'Revenue',
    'Trajectory',
    'Arena',
    'Individual Performance',
    'Reputation',
    'Clients',
    'Total',
    ],
    datasets, // Usar el array de datasets actualizado
};

const options = {
    maintainAspectRatio: false,
    scales: {
    r: {
        type: 'customRadial', // Usar la escala personalizada
        min: 0,
        max: 100,
        ticks: {
        min: 0,
        beginAtZero: true,
        stepSize: 20,
        showLabelBackdrop: false,
        color: theme.palette.gray4,
        padding: 10,
        labelOffset: 5,
        font: {
            size: 0,
            family: theme.typography.fontFamily,
            lineHeight: 1.5,
        },
        },
        angleLines: {
        display: true,
        color: theme.palette.gray4,
        },
        grid: {
        color: theme.palette.gray4,
        },
        pointLabels: {
        color: theme.palette.gray1,
        font: {
            size: 13,
            family: theme.typography.fontFamily,
        },
        callback: function (label) {
            if (label === 'Individual Performance') {
            return ['Individual', 'Performance'];
            }
            return label;
        },
        },
    },
    },
    plugins: {
    legend: {
        display: true, // Mostrar leyenda
        position: 'top',
        labels: {
        color: theme.palette.gray1,
        font: {
            size: 12,
            family: theme.typography.fontFamily,
        },
        },
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        titleColor: theme.palette.gray1,
        bodyColor: theme.palette.gray2,
        borderWidth: 1,
        borderColor: theme.palette.gray4,
        titleFont: {
        size: 12,
        family: theme.typography.fontFamily,
        },
        bodyFont: {
        size: 12,
        family: theme.typography.fontFamily,
        },
    },
    },
};

return (
    <div style={{ display: 'flex', padding: '20px', height: '400px' }}>
    {/* Gráfico alineado a la izquierda */}
    <div style={{ width: '60%' }}>
        <BalanceRadarChart data={data} options={options} />
    </div>

    {/* Select interactivo a la derecha */}
    <div style={{ width: '40%', paddingLeft: '20px' }}>
        <FormControl fullWidth>
        <InputLabel id="firma-select-label">Seleccionar Firmas</InputLabel>
        <Select
            labelId="firma-select-label"
            id="firma-select"
            multiple // Habilitar selección múltiple
            value={selectedFirmas}
            label="Seleccionar Firmas"
            onChange={(event) => setSelectedFirmas(event.target.value)}
            renderValue={(selected) => selected.join(', ')}
        >
            {/* Generar dinámicamente MenuItems basados en las firmas disponibles */}
            {Object.keys(firmas).map((firma) => (
            <MenuItem key={firma} value={firma}>
                {firma}
            </MenuItem>
            ))}
        </Select>
        </FormControl>
    </div>
    </div>
);
};

export default MarketComparative;
