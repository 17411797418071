import React from 'react';
import { Container, Box, Typography, Paper, Link } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Box sx={{ backgroundColor: '#F67147' }}>
            <Container
                sx={{
                    backgroundColor: '#F67147', // Color de fondo para toda la pantalla
                    minHeight: '100vh',
                    padding: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
            <Paper
                sx={{
                backgroundColor: '#fff',
                color: '#000',
                padding: '30px',
                borderRadius: '10px',
                width: '100%',
                }}
            >
                <Box display="flex" justifyContent="center">
                    <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 'bold', textAlign: 'center' }}>
                        Privacy Notice for Soubid
                    </Typography>
                </Box>
                <Typography sx={{ marginBottom: '15px' }}>
                Soubid Inc and its affiliated businesses (collectively, “Soubid,” “we,” “our”) are committed to being responsible custodians of the personal information that we collect in the course of operating our business as a global provider of personal rating and other services.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                This Privacy Notice (“Notice”) describes how we collect, use, share, or otherwise process personal information as a data controller in the course of operating our business, particularly in association with our marketing and business development practices and the operation of our main website (<Link href="http://www.soubid.com">www.soubid.com</Link>), and our other digital properties that link to this Notice, including our websites, mobile applications, and digital communications (collectively, “Digital Services”).
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                “Personal information” as used in this Notice means information that identifies, relates to, describes, or can reasonably be linked, directly or indirectly, to a specific natural person. It does not include information that is considered anonymous, de-identified, or aggregated by applicable law.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                This Notice covers the following topics:
                </Typography>
                <ol>
                        <li>The Types of Information We Collect</li>
                        <li>How We Use the Information We Collect</li>
                        <li>How We May Share the Information We Collect</li>
                        <li>Grounds for Processing Your Information</li>
                        <li>Third-Party Services and Content</li>
                        <li>Protection and Storage of the Information We Collect</li>
                        <li>Your Choices and Rights</li>
                        <li>How to Contact Us</li>
                        <li>Changes to This Privacy Policy</li>
                </ol>
                <br />
                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                1. The Types of Information We Collect
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                The types of personal information we collect about you depend on your interactions with us and are described below.
                </Typography>
                <Typography sx={{ my: '20px', fontStyle: 'italic' }}>
                    Information You Provide to Us
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We collect personal information that you provide to us, mainly through the Login process and the completion of your personal profile. We also may collect personal information when you contact us, request that we send you newsletters, alerts, or other materials, fill out a form, respond to a survey, or otherwise communicate with us.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Depending on the context of your interactions with us, the categories of personal information we may collect from you include:
                </Typography>
                <ul>
                        <li>Unique Identifiers that we can use to identify you as a unique individual, such as your name, picture, telephone number, postal or e-mail address, bar license number, and government issued identification numbers (such as your social security number or passport number). If you have registered with a Digital Service, we may also collect your account username and password.</li>
                        <li>Demographic Information, which may include sensitive personal information or information about protected classifications, such as your age, gender, race, sexual orientation, or marital status.</li>
                        <li>Professional and Educational Information related to your occupation, such as your current or previous employers, your job title, or other information about the organization with which you are affiliated. You might also provide information about your professional qualifications or your education, such as your bar information, degrees, fields of study, the institutions you’ve attended, languages, professional memberships, qualifications, and certifications.</li>
                    <li>Geolocation Information, such as your city, state or province, and country. When you access the Digital Services, we may collect precise geolocation information if you permit the Digital Services to access such information so that we may provide you with content based on your location and your proximity to our offices.</li>
                    <li>Preferences, such as your stated interests, how frequently you wish to receive our newsletters, or other communications.</li>
                    <li>Any other information you choose to provide.</li>
                </ul>
                <Typography sx={{ marginBottom: '15px' }}>
                You generally can use our Digital Services without providing us with personal information, though we may still collect personal information automatically as described in the next section.
                </Typography>

                <Typography sx={{ my: '20px', fontStyle: 'italic' }}>
                    Information We May Collect Automatically or Generate
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We may automatically collect and generate information from and about you when you interact with us or our Digital Services, which may include the following categories of personal information:
                </Typography>
                <ul>
                    <li>Unique Identifiers: We might automatically collect information that uniquely identifies you or the device through which you interact with our Digital Services, such as your name, e-mail address, username, social media identifiers, IP address, and device and mobile advertising identifiers.</li>
                    <li>Device Information: We might automatically collect information pertaining to the device through which you interact with our Digital Services, such as the type of device used to interact with the Digital Services, that device’s operating system and version, your browser type.</li>
                    <li>Interaction Information: We might automatically collect information about your interaction with our Digital Services, such as the content you view and features you access on our Digital Services, the pages you view immediately before and after you access our Digital Services, whether and how you interact with content available on our Digital Services, and the search terms you enter on our Digital Services. We might also collect information about the events and materials in which you have indicated interest or for which you have registered or requested.</li>
                    <li>Geolocation Information: We might automatically collect information regarding your location, such as your ZIP code, city, state or province, country, or general geographic location as derived from your device data (such as your IP address).</li>
                    <li>Inferences drawn from your interactions with our Digital Services, the events you choose to attend, or any of the other personal information available to us.</li>
                </ul>
                <Typography sx={{ marginBottom: '15px' }}>
                We and our service providers or third parties engaged on our behalf may use cookies and similar tracking technologies (collectively, “Cookies”) to collect information from and store information on your device when you use, access, or otherwise interact with our Digital Services. We do not currently respond to “Do Not Track” signals.
                </Typography>

                <Typography sx={{ my: '20px', fontStyle: 'italic' }}>
                    Information We Collect From Other Sources
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We may obtain personal information from other sources, such as the Internet, academic institutions, corporations, law firms, other users, employees, or business partners, or from business contact databases and enrichment services. We may also receive information about you from social media platforms, such as when you interact with us on those platforms or access our social media content. We may collect information about you from publicly available sources, such as public social media profiles, publications, and other websites or materials available through search engines.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We may collect the following categories of information from these other sources:
                </Typography>
                <ul>
                    <li>Unique Identifiers, such as name, telephone number, postal or e-mail address, social media identifiers, and device and mobile advertising identifiers.</li>
                    <li>Professional and Educational Information about your occupation and professional activities, such as professional licenses held, employers, business contact information, or information about your education, such as your degrees, fields of study, institutions attended, languages, professional memberships, qualifications, and certifications.</li>
                    <li>User Content you post or make available online, such as your social media posts or publications.</li>
                </ul>

                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                2. How We Use The Information We Collect
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We may use the information we collect for the various purposes, including:
                </Typography>
                <ul>
                    <li>To display your public profile in our Digital Services</li>
                    <li>To respond to your inquiries or communications.</li>
                    <li>To operate, troubleshoot, and improve the Digital Services.</li>
                    <li>To send you newsletters, legal updates, marketing communications, and other information or materials that may interest you.</li>
                    <li>To expand and maintain our list of contacts.</li>
                    <li>To manage your online account(s) with our Digital Services and send you technical notices, updates, security alerts, and other administrative messages.</li>
                    <li>To understand how people use our services, including by generating and analyzing statistics.</li>
                    <li>For Soubid’ business purposes, including internal administration, data analysis, billing, and detecting, preventing, and responding to actual or potential fraud, illegal activities, or intellectual property infringement.</li>
                    <li>To advertise, provide, and assess the effectiveness of our events, promotional campaigns, publications, and services.</li>
                    <li>As we believe reasonably necessary or appropriate to comply with our legal obligations, to respond to legal process or requests for information issued by government authorities or other third parties, or to protect your, our, or others’ rights.</li>
                </ul>
                <Typography sx={{ marginBottom: '15px' }}>
                We may use anonymous, de-identified, or aggregate information that does not reasonably identify you for any purpose, as permitted by applicable law.
                </Typography>

                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                3. How We May Share the Information We Collect
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Soubid does not sell your personal information. We also do not share personal information that reasonably identifies you with unaffiliated entities for their independent use except when we have your permission, are doing so at your direction, as needed to comply with our legal obligations, as permitted by applicable law, or as otherwise described in this Notice.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We may also disclose the categories of information we collect to the following categories of recipients in furtherance of the purposes described above:
                </Typography>
                <ul>
                    <li>Our affiliates: We may share information with our affiliates located in jurisdictions around the world for any of the purposes listed above.</li>
                    <li>Our service providers: We may share information with third parties that perform services on our behalf, such as web-hosting companies, mailing vendors, analytics providers, and information technology providers.</li>
                    <li>Law enforcement, government authorities, or third parties with legal rights: We may share information as may be permitted or required by the laws of any jurisdiction that may apply to us, as provided for under contract, or as we deem reasonably necessary to provide legal services. In these circumstances, we strive to take reasonable efforts to notify you before we disclose information that may reasonably identify you or your organization, unless prior notice is prohibited by applicable law or is not possible or reasonable in the circumstances.</li>
                    <li>Parties in connection with a business transaction: We may share information with service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets, as well as any bankruptcy or corporate reorganization.</li>
                    <li>Others with your consent or at your direction.</li>
                </ul>
                <Typography sx={{ marginBottom: '15px' }}>
                We may share anonymous, de-identified, or aggregate information that cannot reasonably identify you with others for any purpose, as permitted by applicable law. Please note that any personal information that you post to a profile, blog, comment section, or forum on our Digital Services or social media pages may be available to other users of those forums or, in some cases, made publicly available.
                </Typography>

                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                4. Grounds for Processing Your Information
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Certain jurisdictions require businesses such as Soubid to have legal grounds for processing personal information. Where applicable law requires us to justify our processing activities, we rely on the following legal grounds:
                </Typography>
                <ul>
                    <li>Performance of a contract: We may need to collect and use your personal information to enter into a contract you or to perform a contract that you have with us. For example, when you use our Digital Services we will use your personal information to respond to your requests and provide you with such services.</li>
                    <li>Consent: Where required to obtain your consent, we will do so. For example, certain jurisdictions require us to obtain your consent to process precise location information. To withdraw your consent to such use, you can contact us via our Contact Us page, or, with respect to precise location information, you may be able to disable the sharing of location information in your browser or mobile application settings. Where required by applicable laws, we will rely on your consent for direct marketing and to collect information from your device or computer.</li>
                    <li>Legitimate interests: We may use your personal information for our legitimate interests to improve our products and services, as well as the content on our Digital Services. Consistent with our legitimate interests and any choices that we offer or consents that may be required under applicable laws, we may use technical information and personal information for our marketing purposes.</li>
                    <li>Legal obligation: We may process personal information as necessary to comply with our legal obligations.</li>
                </ul>

                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                5. Third-Party Services and Content
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Our Digital Services may include integrated content or links to content provided by third parties (such as video materials). This Privacy Notice does not address the privacy, security, or other practices of the third parties that provide such content.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Consistent with applicable law, we engage third parties that support the operation of our Digital Services, such as analytics providers. These third parties may track your online activities over time and across different websites and applications. 
                </Typography>

                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                6. Protection and Storage of the Information We Collect
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We deploy commercially reasonable administrative, technical, and physical safeguards designed to comply with applicable legal requirements and safeguard the information that we collect. This includes, when required or appropriate and feasible, obtaining written assurances from third parties that may access your personal information that they will protect such information with safeguards designed to provide a level of protection equivalent to those adopted by Soubid.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                However, no information system can be 100% secure. We cannot guarantee the absolute security of your information. Moreover, we are not responsible for the security of information you transmit to us over networks that we do not control, including the Internet and wireless networks.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                The Digital Services are controlled and operated by Soubid from Chile. We may store the information we collect in Chile or in other countries where we or our service providers have facilities. We may transfer information to countries outside of your country of residence, including the United States, which may have data protection laws and regulations that differ from those in your country.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Any transfers of information originating from within the European Economic Area, the United Kingdom, and Switzerland to countries outside the EEA, UK, or Switzerland will normally be made on the basis of the model clauses approved by the European Commission for transfers from EU controllers to non-EU controllers, which may be found at the Commission's website at <Link href="https://ec.europa.eu">https://ec.europa.eu</Link>. We retain the information we collect no longer than as reasonably necessary to fulfill the purposes for which we collect the information and to comply with our legal obligations.
                </Typography>

                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                7. Your Choices and Rights
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Marketing Communications. If you no longer wish to receive marketing communications from us, you can let us know by contacting us using the information available in the How to Contact Us section below. Our digital marketing communications may provide unsubscribe or opt-out mechanisms that allow you to modify your communications preferences. Please note that if you opt-out of marketing communications, we may still contact you with non-promotional communications, such as those about ongoing business relations or administrative messages.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Cookies.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Geolocation Data. If you have previously consented to sharing precise geolocation information with our Digital Services, you can choose to stop the collection of this information at any time by changing the preferences on your browser or mobile device settings.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Push Notifications/Alerts. If you have permitted one of our mobile applications to send you push notifications or alerts, you can deactivate these messages at any time in the notification settings on your mobile device.
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                Privacy Rights. Soubid operates in jurisdictions that impose varying obligations and that grant individuals differing levels of protection, particularly with respect to personal information. Depending on applicable law, you may have certain rights with respect to your personal information, including, for example, the rights to:
                </Typography>
                <ul>
                    <li>Request access to certain personal information of yours that we process.</li>
                    <li>Update or correct your personal information if you believe it is incomplete or inaccurate.</li>
                    <li>Erase or delete your personal information, subject to certain exceptions under local law.</li>
                    <li>Object to our processing of your personal information or otherwise restrict our processing activities.</li>
                    <li>Restrict certain transfers of your personal information to third parties.</li>
                    <li>Withdraw your consent to processing, where we base our processing of your personal information on consent.</li>
                    <li>Not be denied goods or services for exercising your rights under applicable law.</li>
                    <li>Complain to your local supervisory authority.</li>
                </ul>
                <Typography sx={{ marginBottom: '15px' }}>
                You can exercise your rights (if applicable) by submitting a request through our Contact Us page or emailing our Data Protection Officer at <span style={{ color: '#2C3E50' }}>people@soubid.com</span>.
                </Typography>

                <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                8. Changes to this Privacy Notice
                </Typography>
                <Typography sx={{ marginBottom: '15px' }}>
                We may update this Notice periodically, and will revise the date at the top of this Notice to reflect the date when such update occurred. If we make any material changes in the way we collect, use, and/or share the personal information that you have provided, we will endeavor to provide you with notice before such changes take effect, such as by posting prominent notice on the Soubid website.
                </Typography>
            </Paper>
            </Container>
        </Box>
    );
};

export default PrivacyPolicy;
