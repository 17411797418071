import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Avatar, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import UploadIcon from '@mui/icons-material/Upload';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import logo from '../utils/LogoBlack.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { publicAxios } from '../apiClients';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import soubidUser from '../assets/images/Soubid-user.png'


const OnboardingRequestAccess = () => {
  const navigate = useNavigate();
  const { user, checkCurrentUser } = useAuth();
  const [step, setStep] = useState(1);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState(user?.email || ''); // Asegurarse de que el correo esté disponible
  const [currentQueueNumber, setCurrentQueueNumber] = useState(null);
  const [userQueueNumber, setUserQueueNumber] = useState(null);

  useEffect(() => {
    const fetchQueueNumbers = async () => {
      if (email) {
        try {
          const response = await publicAxios.post('/back-office/check-queue', { email });
  
          if (response.data.success) {
            const { currentQueueNumber, userQueueNumber } = response.data;
            setCurrentQueueNumber(currentQueueNumber);
            setUserQueueNumber(userQueueNumber);
          } else {
            showSnackbar(response.data.message, 'error');
          }
        } catch (error) {
          console.error('Error al consultar el estado de la cola:', error);
          showSnackbar('Error al consultar el estado de la cola.', 'error');
        }
      }
    };
  
    checkCurrentUser();
    fetchQueueNumbers();
  }, []); // <-- Dependencias vacías asegura que se ejecute solo una vez
  

  useEffect(() => {
    if (user?.data?.City) {
      setStep(2);
    }
  }, [user]);

  const handleProfileCompletion = () => {
    navigate('/myprofile');
  };

  const handleUploadDocuments = () => {
    setStep(3);
    navigate('/choose-document', { state: { source: 'RequestAccess' } });
  };

  return (
    <Box sx={{ flex: 1, backgroundColor: '#FFFFFF', padding: 3 }}>
      {/* Header con los datos del usuario */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 10, mt: 0 }}>
        <Avatar
          alt={user?.data?.fullName || 'Usuario'}
          src={user?.linkPhoto || soubidUser}
          sx={{ width: 100, height: 100, mx: 2, mb: 2 }}
        />
        <Box sx={{ mx: 2, mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {user?.data?.fullName || 'Nombre del Usuario'}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: isMobile ? '0.6rem' : '0.9rem' }}>
            {user?.data?.ExpertiseArea1?.name} {user?.data?.ExpertiseArea2?.name ? '/' : ''} {user?.data?.ExpertiseArea2?.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" sx={{ mr: 1, fontFamily: 'Poppins', fontWeight: 'regular', fontSize: isMobile ? '0.6rem' : '0.9rem' }}>
            {user?.data?.City?.name}{user?.data?.City ? ',' : ''} {user?.data?.Country?.name}
          </Typography>
        </Box>
        <Box sx={{ ml: 'auto', mt: -3 }}>
          <img src={logo} alt="Logo Soubid" style={{ height: 50 }} />
        </Box>
      </Box>

      {!isMobile && (
        <>
          {/* Línea punteada que une el número 1 con el número 2 */}
          <Box
            sx={{
              position: 'absolute',
              top: '266px', 
              left: 'calc(17% + 20px)',  
              width: 'calc(33% - 40px)', 
              height: 38.5,
              borderBottom: '2px dashed #F67147',
              zIndex: 1,
            }}
          />

          {/* Línea punteada que une el número 2 con el número 3 */}
          <Box
            sx={{
              position: 'absolute',
              top: '266px', 
              left: 'calc(50% + 20px)', 
              width: 'calc(33% - 60px)', 
              height: 38.5,
              borderBottom: '2px dashed #F67147',
              zIndex: 1,
            }}
          >
            {/* Flecha al final de la línea punteada */}
            <ArrowForwardIcon
              sx={{
                position: 'absolute',
                top: '25px',
                right: 0,
                transform: 'translateX(90%)',
                color: '#F67147',
              }}
            />
          </Box>
        </>
      )}

      <Grid container spacing={4} sx={{ justifyContent: 'center', position: 'relative', mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: 'center', position: 'relative', zIndex: 2 }}>
            <Box
              sx={{
                backgroundColor: '#F67147',
                color: '#FFFFFF',
                width: 40,
                height: 40,
                borderRadius: '50%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}
            >
              1
            </Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Rellenar Perfil
            </Typography>
            <Box
              sx={{
                backgroundColor: step > 1 ? '#F0F0F0' : '#F67147',
                color: step > 1 ? '#B0B0B0' : '#FFFFFF',
                borderRadius: 2,
                p: 3,
                textAlign: 'center',
                maxWidth: 300,
                margin: '0 auto',
              }}
              onClick={handleProfileCompletion}
            >
              <PersonIcon sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="body2">
                Completa tu perfil para continuar al siguiente paso.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: 'center', position: 'relative', zIndex: 2 }}>
            <Box
              sx={{
                backgroundColor: step >= 2 ? '#F67147' : '#F0F0F0',
                color: step >= 2 ? '#FFFFFF' : '#B0B0B0',
                width: 40,
                height: 40,
                borderRadius: '50%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}
            >
              2
            </Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Subir Antecedentes
            </Typography>
            <Box
              sx={{
                backgroundColor: step >= 2 ? '#F67147' : '#F0F0F0',
                color: step >= 2 ? '#FFFFFF' : '#B0B0B0',
                borderRadius: 2,
                p: 3,
                textAlign: 'center',
                cursor: step === 2 ? 'pointer' : 'not-allowed',
                maxWidth: 300,
                margin: '0 auto',
              }}
              onClick={step === 2 ? handleUploadDocuments : null}
            >
              <UploadIcon sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="body2">
                Sube los documentos necesarios para verificar tu cuenta.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: 'center', position: 'relative', zIndex: 2 }}>
            <Box
              sx={{
                backgroundColor: step === 3 ? '#F67147' : '#F0F0F0',
                color: step === 3 ? '#FFFFFF' : '#B0B0B0',
                width: 40,
                height: 40,
                borderRadius: '50%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}
            >
              3
            </Box>
            <Box
              sx={{
                backgroundColor: step === 3 ? '#F67147' : '#F0F0F0',
                color: step === 3 ? '#FFFFFF' : '#B0B0B0',
                borderRadius: 2,
                p: 3,
                textAlign: 'center',
                maxWidth: 300,
                margin: '0 auto',
              }}
            >
              <CheckCircleIcon sx={{ fontSize: 40, mb: 2, color: step === 3 ? 'green' : '#B0B0B0' }} />
              <Typography variant="body2">
                Ya eres parte de la comunidad Soubid.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Typography variant="body1" sx={{ textAlign: 'center', mt: 4 }}>
        Ahora estás en la fila para entrar a la plataforma Soubid. Tu número actual es <strong>{userQueueNumber !== null ? userQueueNumber : 'Cargando...'}</strong>.
      </Typography>
    </Box>
  );
};

export default OnboardingRequestAccess;
