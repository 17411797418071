import React from 'react';
import { Box, Grid, Typography, LinearProgress, useMediaQuery, Button, IconButton } from '@mui/material';
import BalanceIcon from '@mui/icons-material/Balance'
import RadarChart from './radarChart'; // Asegúrate de que el componente del gráfico esté implementado.
import theme from '../../styles/StyleTheme'; // Importa tu archivo de estilos.
import AppleStoreBadge from '../../assets/home/appstore.png';
import GooglePlayBadge from '../../assets/home/google.png';
import logo from '../../assets/logo/LogoBlack.png';
import QRApp from '../../assets/images/qr-app.png';
import { useNavigate } from 'react-router-dom';

const MarketFirm = () => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const handleClickContact = () => {
        navigate('/contact');
    }

    const handleClickBalance = () => {
        navigate('/market-comparative');
    }

    return (
        <>
        <Box sx={{ padding: 3, backgroundColor: theme.palette.gray5 }}>
            {/* Encabezado */}
            <Box
                sx={{
                    backgroundColor: theme.palette.white,
                    borderRadius: 2,
                    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
                    padding: 3,
                    marginBottom: 3,
                }}
            >
                <Grid container spacing={2} alignItems="center">

                    {/* Columna 1: Nombre */}
                    <Grid item xs={6}>
                        <Typography variant="h5" sx={{ color: theme.palette.black, fontWeight: 'bold' }}>
                            Latham & Walktins
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.gray3 }}>
                            United States
                        </Typography>
                    </Grid>

                    {/* Columna 2: Otros datos */}
                    <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: theme.palette.gray2, textAlign: 'right' }}>
                            Score: <span style={{ color: theme.palette.primary.main, display: 'inline-block', minWidth: '50px', textAlign: 'right', fontWeight: 'bold' }}>0.0</span>
                        </Typography>
                        <Typography variant="body1" sx={{ color: theme.palette.gray2, textAlign: 'right' }}>
                            Local position: <span style={{ color: theme.palette.primary.main, display: 'inline-block', minWidth: '50px', textAlign: 'right', fontWeight: 'bold' }}>1</span>
                        </Typography>
                        <Typography variant="body1" sx={{ color: theme.palette.gray2, textAlign: 'right' }}>
                            Global position: <span style={{ color: theme.palette.primary.main, display: 'inline-block', minWidth: '50px', textAlign: 'right', fontWeight: 'bold' }}>0.0</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* Grilla principal */}
            <Grid
                container
                spacing={2}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gridTemplateRows: 'auto auto auto',
                }}
            >
                {/* Gráfico que ocupa dos columnas y dos filas */}
                <Grid
                    item
                    sx={{
                        gridColumn: '1 / span 2',
                        gridRow: '1 / span 2',
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative', // Posicionamiento relativo para colocar el botón de manera absoluta dentro del contenedor
                            backgroundColor: theme.palette.white,
                            borderRadius: '16px',
                            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
                            padding: '20px',
                            height: '100%',
                            minHeight: '400px',
                        }}
                    >
                        <RadarChart /> {/* Componente del gráfico */}

                        {/* Botón con icono de balanza en la esquina inferior derecha */}
                        <IconButton
                            sx={{
                                position: 'absolute',
                                bottom: 16,
                                right: 16,
                                backgroundColor: '#75838E', // Color gris similar al de la imagen
                                color: theme.palette.white,
                                borderRadius: '12px', // Bordes redondeados
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Sombra similar a la imagen
                                width: 48,
                                height: 48,
                                '&:hover': {
                                    backgroundColor: '#5A6777', // Color más oscuro al hacer hover
                                },
                            }}
                            onClick={handleClickBalance}
                        >
                            <BalanceIcon />
                        </IconButton>
                    </Box>
                </Grid>

                {/* Boxes en la primera fila */}
                <Grid item sx={{ gridColumn: '3' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h3" sx={{ color: '#3592D2' }}>
                            80
                        </Typography>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                            Partners
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sx={{ gridColumn: '4' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                        <Typography variant="h3" sx={{ color: '#3592D2', marginTop: 3.5 }}>
                            255
                        </Typography>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', marginTop: 0.5 }}>
                            Other qualified<br />members
                        </Typography>
                    </Box>
                </Grid>

                {/* Boxes en la segunda fila */}
                <Grid item sx={{ gridColumn: '3', gridRow: '2' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Rankings reputation
                        </Typography>
                        <Typography variant="h3" sx={{ color: '#3592D2', marginY: 0.8 }}>
                            83.4
                        </Typography>
                        <Box sx={{ width: '70%', marginTop: 1.5 }}>
                            <LinearProgress variant="determinate" value={83.4} sx={{ height: 10, borderRadius: 5, backgroundColor: '#DBEAF6', '& .MuiLinearProgress-bar': {backgroundColor: '#3592D2'}}} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item sx={{ gridColumn: '4', gridRow: '2' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Revenue
                        </Typography>
                        <Typography variant="h3" sx={{ color: '#3592D2', marginY: 0.8 }}>
                            53.4
                        </Typography>
                        <Box sx={{ width: '70%', marginTop: 1.5 }}>
                            <LinearProgress variant="determinate" value={53.4} sx={{ height: 10, borderRadius: 5, backgroundColor: '#DBEAF6', '& .MuiLinearProgress-bar': {backgroundColor: '#3592D2'}}} />
                        </Box>
                    </Box>
                </Grid>

                {/* Boxes en la tercera fila */}
                <Grid item sx={{ gridColumn: '1' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Trajectory
                        </Typography>
                        <Typography variant="h3" sx={{ color: '#3592D2', marginY: 0.8 }}>
                            83.4
                        </Typography>
                        <Box sx={{ width: '70%', marginTop: 1.5 }}>
                            <LinearProgress variant="determinate" value={83.4} sx={{ height: 10, borderRadius: 5, backgroundColor: '#DBEAF6', '& .MuiLinearProgress-bar': {backgroundColor: '#3592D2'}}} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item sx={{ gridColumn: '2' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Top active clients
                        </Typography>
                        <Typography variant="h3" sx={{ color: '#3592D2', marginY: 0.8 }}>
                            23.2
                        </Typography>
                        <Box sx={{ width: '70%', marginTop: 1.5 }}>
                            <LinearProgress variant="determinate" value={23.2} sx={{ height: 10, borderRadius: 5, backgroundColor: '#DBEAF6', '& .MuiLinearProgress-bar': {backgroundColor: '#3592D2'}}} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item sx={{ gridColumn: '3' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Individual performance
                        </Typography>
                        <Typography variant="h3" sx={{ color: '#3592D2', marginY: 0.8 }}>
                            97.4
                        </Typography>
                        <Box sx={{ width: '70%', marginTop: 1.5 }}>
                            <LinearProgress variant="determinate" value={97.4} sx={{ height: 10, borderRadius: 5, backgroundColor: '#DBEAF6', '& .MuiLinearProgress-bar': {backgroundColor: '#3592D2'}}} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sx={{ gridColumn: '4' }}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.white,
                            padding: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            minHeight: '190px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Arena
                        </Typography>
                        <Typography variant="h3" sx={{ color: '#3592D2', marginY: 0.8 }}>
                            100
                        </Typography>
                        <Box sx={{ width: '70%', marginTop: 1.5 }}>
                            <LinearProgress variant="determinate" value={100} sx={{ height: 10, borderRadius: 5, backgroundColor: '#DBEAF6', '& .MuiLinearProgress-bar': {backgroundColor: '#3592D2'}}} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        {/* Contacto */}
        <Box
            sx={{
                backgroundColor: theme.palette.gray5, // Puedes ajustar el color de fondo según necesites
                padding: 8,
                borderRadius: 2,
                textAlign: 'center',
            }}
        >
            <Typography variant="body1" sx={{ color: theme.palette.gray1, marginBottom: 1 }}>
                Information comes from public sources or is provided by the firms and verified by our team.
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.gray1, marginBottom: 3 }}>
                Are you a member in this Firm and want to update displayed information?
            </Typography>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#f16548',
                    color: '#fff',
                    paddingX: 3,
                    paddingY: 1,
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#e15538',
                    },
                }}
                onClick={handleClickContact}
            >
                Contact us
            </Button>
        </Box>

        {/* Descarga de la APP */}
        <Grid container alignItems="center" justifyContent="center" sx={{ width: '100%', bgcolor: '#F67147' }}>
            <Box container alignItems="center" justifyContent="center" sx={{
                p: 2,
                textAlign: 'center',
                width: '100%',
            }}>
            <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={10}>
                <Grid item>
                <Box component="img" src={logo} alt="Logo"
                    sx={{
                    height: 100,
                    width: 100,
                    alignSelf: 'center',
                    mt: 4,
                    mr: 3
                    }}
                />
                </Grid>
                <Grid item>
                <Typography variant="body1" sx={{ color: 'white', mb: 2, fontWeight: 'bold' }}>
                    Conoce nuestra APP
                </Typography>
                <Box component="img" src={QRApp} alt="QR Code"
                    sx={{
                    height: 100,
                    width: 100,
                    alignSelf: 'center',
                    borderRadius: '5px',
                    }}
                />
                </Grid>
            </Grid>
            {isMobile && (
                <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
                    <a href="https://apps.apple.com/cl/app/soubid/id6578461746" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={AppleStoreBadge} alt="Download on the App Store" sx={{ height: 40, marginRight: 2 }} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={GooglePlayBadge} alt="Get it on Google Play" sx={{ height: 40 }} />
                    </a>
                </Box>
                </>
            )}
            </Box>
        </Grid>
        </>
    );
};

export default MarketFirm;
