import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Box, TableSortLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Estilos aplicados
const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: 0,
  backgroundColor: theme.palette.gray5
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  borderRadius: '10px',
  marginBottom: '5px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
}));

const LeftAlignTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  borderBottom: 'none',
  fontSize: '14px',
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  flex: 1, 
  justifyContent: 'flex-start',
}));

const CenterAlignTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  borderBottom: 'none',
  fontSize: '14px',
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  flex: 1, 
  justifyContent: 'center',
}));

const NarrowTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  borderBottom: 'none',
  fontSize: '14px',
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 120px',
  justifyContent: 'center',
}));

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  borderRadius: '10px',
  marginBottom: '5px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const FirmTable = ({ searchTerm, selectedCountry, sortBy, handleSort }) => {
  const navigate = useNavigate();

  const firms = [
    { position: 1, name: 'Latham & Walkins', country: 'US', totalScore: 100 },
    { position: 2, name: 'Clifford Chance', country: 'UK', totalScore: 99.8 },
    { position: 3, name: 'Sullivan & Cromwell', country: 'US', totalScore: 97 },
    { position: 4, name: 'Ashurst', country: 'Australia', totalScore: 94.7 },
    { position: 5, name: 'Blakes', country: 'Canada', totalScore: 90 },
    // Agrega más filas aquí
  ];

  // Función para ordenar los datos según la columna seleccionada
  const sortFirms = (firms) => {
    return firms.sort((a, b) => {
      if (sortBy.field === 'position') {
        return sortBy.direction === 'asc' ? a.position - b.position : b.position - a.position;
      }
      if (sortBy.field === 'name') {
        return sortBy.direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      }
      if (sortBy.field === 'country') {
        return sortBy.direction === 'asc' ? a.country.localeCompare(b.country) : b.country.localeCompare(a.country);
      }
      if (sortBy.field === 'totalScore') {
        return sortBy.direction === 'asc' ? a.totalScore - b.totalScore : b.totalScore - a.totalScore;
      }
      return 0;
    });
  };

  // Filtrar las firmas según el término de búsqueda y el país seleccionado
  const filteredFirms = firms.filter(firm =>
    firm.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedCountry === '' || firm.country === selectedCountry)
  );

  // Ordenar las firmas según el criterio de ordenación
  const sortedFirms = sortFirms(filteredFirms);

  const handleClick = () => {
    navigate('/market-firm');
  };

  return (
    <StyledContainer>
      <TableContainer component={Paper} style={{ border: 'none', boxShadow: 'none', backgroundColor: '#EEF1F4' }}>
        <Table>
          <TableHead>
            <StyledTableHeadRow>
              {/* Ordenar por Position */}
              <NarrowTableCell>
                <TableSortLabel
                  active={sortBy.field === 'position'}
                  direction={sortBy.field === 'position' ? sortBy.direction : 'asc'}
                  onClick={() => handleSort('position')}
                  hideSortIcon={sortBy.field !== 'position'}
                >
                  Position
                </TableSortLabel>
              </NarrowTableCell>

              {/* Ordenar por Name */}
              <LeftAlignTableCell>
                <TableSortLabel
                  active={sortBy.field === 'name'}
                  direction={sortBy.field === 'name' ? sortBy.direction : 'asc'}
                  onClick={() => handleSort('name')}
                  hideSortIcon={sortBy.field !== 'name'}
                >
                  Name
                </TableSortLabel>
              </LeftAlignTableCell>

              {/* Ordenar por Country */}
              <CenterAlignTableCell>
                <TableSortLabel
                  active={sortBy.field === 'country'}
                  direction={sortBy.field === 'country' ? sortBy.direction : 'asc'}
                  onClick={() => handleSort('country')}
                  hideSortIcon={sortBy.field !== 'country'}
                >
                  Country
                </TableSortLabel>
              </CenterAlignTableCell>

              {/* Ordenar por Total Score */}
              <CenterAlignTableCell>
                <TableSortLabel
                  active={sortBy.field === 'totalScore'}
                  direction={sortBy.field === 'totalScore' ? sortBy.direction : 'asc'}
                  onClick={() => handleSort('totalScore')}
                  hideSortIcon={sortBy.field !== 'totalScore'}
                >
                  Total Score
                </TableSortLabel>
              </CenterAlignTableCell>

              <NarrowTableCell></NarrowTableCell>
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {sortedFirms.map((firm) => (
              <StyledTableRow key={firm.position}>
                <NarrowTableCell>{firm.position}</NarrowTableCell>
                <LeftAlignTableCell>{firm.name}</LeftAlignTableCell>
                <CenterAlignTableCell>{firm.country}</CenterAlignTableCell>
                <CenterAlignTableCell>{firm.totalScore.toFixed(2)}</CenterAlignTableCell>
                <NarrowTableCell>
                  <IconButton color="primary" aria-label="more info" onClick={handleClick}>
                    <AddCircleIcon />
                  </IconButton>
                </NarrowTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledContainer>
  );
};

export default FirmTable;
