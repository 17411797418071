import { useState, useEffect, createContext, useContext } from 'react';
import { fetchAuthSession, signIn as signInAws, signOut as signOutAws, resetPassword as resetPasswordAws, confirmResetPassword as confirmResetPasswordAws, updatePassword as updatePasswordAws } from 'aws-amplify/auth';
import { publicAxios, userAxios } from '../apiClients';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkCurrentUser();
  }, []);

  const checkCurrentUser = async () => {
    try {
      if (localStorage.getItem('accessToken')) {
        const session = await fetchAuthSession({ forceRefresh: true });
        localStorage.setItem('accessToken', session.tokens?.accessToken?.toString());
        const cognitoUserID = session.tokens?.idToken?.payload.sub;
        const attributes = await userAxios.get(`/users/role?cognitoUserID=${cognitoUserID}`);
        setUser({
          userRole: attributes.data?.userRole ?? null,
          state: attributes.data?.state ?? null,
          name: attributes.data?.name ?? null,
          lastName: attributes.data?.lastName ?? null,
          fullName: attributes.data?.fullName ?? null,
          email: attributes.data?.email ?? null,
          cognitoUserID: cognitoUserID,
          id: attributes.data?.id ?? null,
          linkPhoto: attributes.data?.linkPhoto ?? null,
          credits: attributes.data?.credits ?? null,
          place: attributes.data?.City?.name && attributes.data?.Country?.name ? `${attributes.data.City.name}, ${attributes.data.Country.name}` : '',
          countryID: attributes.data?.Country?.id ?? null,
          qualification: attributes.data?.userQualifications ?? {},
          data: attributes.data?.jsonUser ?? {},
          webNotifications: attributes.data?.webNotifications ?? null,
          adminInstitution: attributes.data?.adminInstitution ?? {},
          adminPermission: attributes.data?.adminPermission ?? null,
          adminNotifications: attributes.data?.adminNotifications?.data ?? [],
        });
      }
    } catch (error) {
      console.error('Error checking current user:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const signIn = async (data) => {
    try {
      const username = data.email;
      const password = data.password;
      const { isSignedIn, nextStep } = await signInAws({ username, password });
      if (isSignedIn) {
        const { tokens } = await fetchAuthSession();
        localStorage.setItem('accessToken', tokens.accessToken?.toString());
        const cognitoUserID = tokens.idToken?.payload.sub;
        const attributes = await publicAxios.get(`/users/role?cognitoUserID=${cognitoUserID}`);
        setUser({
          userRole: attributes.data?.userRole ?? null,
          state: attributes.data?.state ?? null,
          name: attributes.data?.name ?? null,
          lastName: attributes.data?.lastName ?? null,
          fullName: attributes.data?.fullName ?? null,
          email: attributes.data?.email ?? null,
          cognitoUserID: cognitoUserID,
          id: attributes.data?.id ?? null,
          linkPhoto: attributes.data?.linkPhoto ?? null,
          credits: attributes.data?.credits ?? null,
          place: attributes.data?.City?.name && attributes.data?.Country?.name ? `${attributes.data.City.name}, ${attributes.data.Country.name}` : '',
          countryID: attributes.data?.Country?.id ?? null,
          qualification: attributes.data?.userQualifications ?? {},
          data: attributes.data?.jsonUser ?? {},
          webNotifications: attributes.data?.webNotifications ?? null,
          adminInstitution: attributes.data?.adminInstitution ?? {},
          adminPermission: attributes.data?.adminPermission ?? null,
          adminNotifications: attributes.data?.adminNotifications?.data ?? [],
        });
        const appActive = await publicAxios.get(`/users/app-active?id=${attributes.data?.id}`);
        localStorage.setItem('notificationAppProfile', JSON.stringify({ active: appActive?.data?.active, count: 0 }));
        localStorage.setItem('notificationAppDirectory', JSON.stringify({ active: appActive?.data?.active, count: 0 }));
        localStorage.setItem('notificationAppHome', JSON.stringify({ active: appActive?.data?.active, count: 0 }));
        const notificationQualification = Object.keys(attributes.data?.userQualifications ?? {}).length > 0;
        localStorage.setItem('notificationQualificationDirectory', JSON.stringify({ active: notificationQualification, count: 0 }));
        localStorage.setItem('notificationQualificationHome', JSON.stringify({ active: notificationQualification, count: 0 }));
        localStorage.setItem('notificationQualificationProfile', JSON.stringify({ active: notificationQualification, count: 0 }));
      }
      return { isSignedIn, nextStep };
    } catch (error) {
      console.error('Error signing in:', error);
      signOut();
    }
  };

  const signOut = async () => {
    try {
      await signOutAws();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('notificationAppProfile');
      localStorage.removeItem('notificationAppDirectory');
      localStorage.removeItem('notificationAppHome');
      localStorage.removeItem('notificationQualificationProfile');
      localStorage.removeItem('notificationQualificationDirectory');
      localStorage.removeItem('notificationQualificationHome');
      localStorage.removeItem('institutionUserIds');
      localStorage.removeItem('paramId');
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const resetPassword = async (email) => {
    try {
      await resetPasswordAws({ username: email });
    } catch (error) {
      console.error('Error sending reset password code:', error);
      throw error;
    }
  };

  const confirmResetPassword = async (email, code, newPassword) => {
    try {
      await confirmResetPasswordAws({ username: email, newPassword: newPassword, confirmationCode: code });
    } catch (error) {
      console.error('Error confirming reset password:', error);
      throw error;
    }
  };

  const updatePassword = async (oldPassword, newPassword) => {
    try {
      await updatePasswordAws({ oldPassword, newPassword });
    } catch (error) {
      console.error('Error changing password:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, signIn, signOut, resetPassword, confirmResetPassword, updatePassword, checkCurrentUser, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
