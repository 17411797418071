import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Grid, IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../hooks/useAuth';
import { resendSignUpCode } from 'aws-amplify/auth';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import ConfirmMail from './ConfirmMail';
import ProfileCard from '../components/home/ProfileCard';
import ModalHome from '../components/home/ModalHome';
import economistas from '../filesjson/insert_economistas.json';
import medicos from '../filesjson/insert_user_medicos.json';
import abogados from '../filesjson/insert_abogados.json';
import Logo from '../assets/logo/LogoBlack.png';
import CountrySelector from './countrySelector';
import { publicAxios } from '../apiClients';


const Home = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); 
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const generateRecaptchaToken = async () => {
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      return new Promise((resolve, reject) => {
        window.grecaptcha.enterprise.ready(async () => {
          try {
            const token = await window.grecaptcha.enterprise.execute(`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, { action: 'LOGIN' });
            if (token) {
              resolve(token);  // Retorna el token directamente
            } else {
              reject('No se pudo generar el token de reCAPTCHA');
            }
          } catch (error) {
            console.error('Error al generar el token de reCAPTCHA', error);
            reject(error);
          }
        });
      });
    } else {
      console.error('reCAPTCHA no está disponible');
      return null;
    }
  };

  const getImageSrc = (type, grade) => {
    if (!type || !grade) return null;
    let typeQualification;
    if (type === 'professional') typeQualification = 'qualificationProfessional';
    if (type === 'academic') typeQualification = 'qualificationAcademic';
    if (type === 'student') typeQualification = 'qualificationStudent';
    try {
      return require(`../assets/${typeQualification}/${grade}.png`);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const selectRandomProfiles = (data, num) => {
      if (!data || !data.length) return [];
      const shuffled = data.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    };
  
    if (economistas && Array.isArray(economistas) && medicos && Array.isArray(medicos) && abogados && Array.isArray(abogados)) {
      const selectedEconomists = selectRandomProfiles(economistas, 4);
      const selectedMedics = selectRandomProfiles(medicos, 4);
      const selectedLawyers = selectRandomProfiles(abogados, 7);
  
      // Ensure the first row contains 3 lawyers, 1 economist, and 1 medic
      const firstRow = [
        ...selectRandomProfiles(selectedLawyers, 3),
        ...selectRandomProfiles(selectedEconomists, 1),
        ...selectRandomProfiles(selectedMedics, 1),
      ];
  
      firstRow.sort(() => 0.5 - Math.random());
  
      const remainingLawyers = selectedLawyers.filter(profile => !firstRow.includes(profile));
      const remainingEconomists = selectedEconomists.filter(profile => !firstRow.includes(profile));
      const remainingMedics = selectedMedics.filter(profile => !firstRow.includes(profile));
  
      const combinedProfiles = [...firstRow, ...remainingLawyers, ...remainingEconomists, ...remainingMedics];
  
      const getProfileData = (profile) => {
        const qualification = profile.qualification;
        const qualificationType = qualification ? Object.keys(qualification)[0] : null;
        const qualificationGrade = qualificationType ? qualification[qualificationType] : null;

        const fullName = [
          profile?.name,
          profile?.secondName,
          profile?.lastName,
          profile?.secondLastName
        ].filter(part => part && part !== 'NULL').join(' ');        
        
        let profileData = {
          id: profile.id,
          name: fullName,
          city: profile.city,
          country: profile.country,
          linkPhoto: profile?.linkPhoto,
          profession: profile.profession,
          university: profile.university,
          company: profile.company,
        };
  
        if (profile.profession === 'Abogado') {
          profileData.rating = getImageSrc(qualificationType, qualificationGrade['grade']);
        } else {
          profileData.rating = getImageSrc(qualificationType, qualificationGrade);
        }
  
        return profileData;
      };
  
      const formattedProfiles = combinedProfiles.map(getProfileData);
  
      setProfiles(formattedProfiles);
    } else {
      console.error('Error: economistas, medicos or abogados is not an array or is undefined');
    }
  }, [economistas, medicos, abogados]);  

  const handleEmailChange = (event) => {
    setEmailError(false);
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordError(false);
    setPassword(event.target.value);
  };
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    let hasError = false;
    if (!email) {
      setEmailError(true);
      hasError = true;
    }
    if (!password) {
      setPasswordError(true);
      hasError = true;
    }
    if (hasError) return;

    try {
      const recaptchaToken = await generateRecaptchaToken();

      if (!recaptchaToken) {
        showSnackbar('No se pudo generar el token de reCAPTCHA', 'error');
        return;
      }

      const recaptchaResponse = await publicAxios.post('/recaptcha/validate', { recaptchaToken });
      if (!recaptchaResponse.data.success) {
        showSnackbar('Falló la validación de reCAPTCHA', 'error');
        return;
      }

      const response = await signIn({ email, password });

      if (!response) {
        showSnackbar('Correo o contraseña inválida', 'error');
        return;
      }
  
      const { isSignedIn, nextStep } = response;
  
      if (isSignedIn) {
        navigate('/home');
        return; // Terminamos la ejecución si el usuario está correctamente autenticado
      }
  
      if (nextStep && nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        await resendSignUpCode({ username: email });
        setShowConfirm(true);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message === 'Fallo en la validación de reCAPTCHA') {
        showSnackbar('Falló la validación de reCAPTCHA', 'error');
      } else {
        console.error('Error al iniciar sesión', error);
        showSnackbar(`Error: ${error.message}`, 'error');
      }
    }
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  if (showConfirm) {
    const userData = { email, password };
    return <ConfirmMail userData={userData} />;
  }

  const customTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
    },
    '& input::placeholder': {
      color: 'black',
      opacity: 0.75,
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0,
    },
    '& .MuiIconButton-root': {
      padding: 2,
      color: 'black',
    },
    '& .MuiFormHelperText-root': {
      color: 'black', // Cambia el color del helper text
    },
    '& .Mui-error': {
      '& .MuiFormHelperText-root': {
        color: 'black', // Cambia el color del helper text en caso de error
      }
    },
  };  

  return (
    <Grid container sx={{ backgroundColor: '#F67147' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <CountrySelector onChange={(selectedCountry) => console.log(selectedCountry)} />
        </Box>      
      <Box
        sx={{
          minHeight: '80vh',
          minWidth: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F67147',
          m: 0,
          p: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
            p: 4,
          }}
        >
          <Box
            component="img"
            sx={{
              height: isMobile ?  100 : '30%',
              width: isMobile ? 100 : '30%',
              mb: 2,
            }}
            alt="Logo"
            src={Logo}
          />
          <Typography variant="h3" sx={{ color: 'black', textAlign: 'center', maxWidth: '80vh', m: 0, p: 1 }}>
            The Rating Network
          </Typography>
          <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', maxWidth: '50vh', m: 0, p: 3 }}>
            Soubid es la primera Red Calificadora de Profesionales a nivel global. Funciona de manera descentralizada, con la participación activa de la comunidad. Aquí no hay conflictos de interés, prejuicios, ni subjetividades. Sólo ciencia.           
          </Typography>
          {!isMobile && 
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Box
                component="a"
                href="https://apps.apple.com/cl/app/soubid/id6578461746"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ mr: 2 }}
              >
                <Box
                  component="img"
                  sx={{ height: 50, width: 150 }}
                  alt="Download on the App Store"
                  src={require('../assets/home/appstore.png')}
                />
              </Box>
              <Box
                component="a"
                href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  component="img"
                  sx={{ height: 50, width: 150 }}
                  alt="Get it on Google Play"
                  src={require('../assets/home/google.png')}
                />
              </Box>
            </Box>
          </>}
        </Box>
        <Box
          sx={{
            width: isMobile ? 350 : 400,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: isMobile ? 2 : 4,
            flex: 1,
          }}
        >
          <Box
            component="form"
            onSubmit={handleSignIn}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              maxWidth: '400px',
            }}
          >
            <TextField
              sx={customTextFieldStyles}
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={handleEmailChange}
              InputLabelProps={{ shrink: false }}
              placeholder="Correo electrónico"
              error={emailError}
              helperText={emailError ? 'Este campo es obligatorio' : ''}
            />
            <TextField
              sx={customTextFieldStyles}
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              InputLabelProps={{ shrink: false }}
              placeholder="Contraseña"
              error={passwordError}
              helperText={passwordError ? 'Este campo es obligatorio' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="primary" fullWidth sx={{ mt: 2, bgcolor: 'black', color: 'white' }} type="submit">
              Iniciar Sesión
            </Button>
            <Link to="/forgot-password" variant="body2" style={{ color: 'white', marginTop: '10px' }}>
              ¿Olvidaste tu contraseña?
            </Link>
            <Button
              component={Link}
              to="/request-access"
              fullWidth
              variant="secondary"
              sx={{ mt: 2, color: 'white', borderColor: 'white' }}
            >
              Solicitar Acceso
            </Button>
          </Box>
        </Box>
        {isMobile && 
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6, mb: 4 }}>
              <Box
                component="a"
                href="https://apps.apple.com/cl/app/soubid/id6578461746"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ mr: 2 }}
              >
                <Box
                  component="img"
                  sx={{ height: 50, width: 150 }}
                  alt="Download on the App Store"
                  src={require('../assets/home/appstore.png')}
                />
              </Box>
              <Box
                component="a"
                href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  component="img"
                  sx={{ height: 50, width: 150 }}
                  alt="Get it on Google Play"
                  src={require('../assets/home/google.png')}
                />
              </Box>
            </Box>
          </>
        }
      </Box>
      <Grid sx={{ backgroundColor: 'black', alignContent: 'center', alignItems: 'center' }}>
        <Typography variant="h3" sx={{ color: 'white', textAlign: 'center', mt: 5 }}>
          Profesionales en Chile
        </Typography>

        <Grid container spacing={2} justifyContent="center" sx={{ pt: 10, pb: 5, backgroundColor: 'black' }}>
          {profiles.map((profile) => (
            <Grid item xs={12} sm={6} md={4} lg={2.3} key={profile.id}>
              <ProfileCard {...profile} onClick={handleModalOpen} />
            </Grid>
          ))}
        </Grid>
        <Button
          sx={{
            display: 'block',
            mx: 'auto',
            mt: 3,
            mb: 5,
            backgroundColor: '#F67147',
            color: 'white',
            borderRadius: '24px',
            '&:hover': {
              backgroundColor: '#E05E3B',
            },
          }}
          onClick={handleModalOpen} 
        >
          Ver más profesionales
        </Button>
      </Grid>
      <ModalHome open={modalOpen} handleClose={handleModalClose} />
    </Grid>
  );
};

const HomeGateway = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'queue') {
        navigate('/onboarding-new');
      } else if (user?.userRole === 'institutional') {
        navigate('/home-institution');
      }
      else {
        navigate('/home-user');  // Redirige al directorio u otra página según el rol del usuario
      }
    }
  }, [user, navigate]); // Ejecuta el efecto cuando `user` se actualiza

  // Mientras no haya usuario, renderiza la pantalla inicial
  return !user && <Home />;
};

export default HomeGateway;
