import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Tooltip, AppBar, Box, Toolbar, IconButton, Container, Avatar, Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import { useAuth } from '../hooks/useAuth';
import SideMenu from '../components/menu/SideMenu';
import laurelCrownLeft from '../assets/images/laurel-crown-left.png';
import laurelCrownRight from '../assets/images/laurel-crown-right.png';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Governance from '../components/Governance'; // Asegúrate de que la ruta sea la correcta
import soubidUser from '../assets/images/Soubid-user.png';

import name from '../utils/name.png';

function ResponsiveAppBar() {
    const { user, loading } = useAuth();
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [isGovernanceOpen, setIsGovernanceOpen] = useState(false);

    const handleToggleSideMenu = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSelected = (route) => {
        navigate(route);
    };

    const handleOpenGovernance = () => {
        setIsGovernanceOpen(true);
    };

    const handleCloseGovernance = () => {
        setIsGovernanceOpen(false);
    };

    if (loading) {
        return (
            <>
                <AppBar position="static">
                    <Container maxWidth="100%" sx={{ backgroundColor: 'black' }}>
                        <Toolbar disableGutters>
                            <Link to="/">
                                <Grid container spacing={2} sx={{ flexGrow: 1, flexWrap: 'nowrap', alignItems: 'center' }} gap={2}>
                                    <Grid item sx={{ minWidth: 10 }}>
                                        <Avatar variant="square" src={name} sx={{ width: '100%', height: '25px', mt: '0px' }} />
                                    </Grid>
                                </Grid>
                            </Link>
                        </Toolbar>
                    </Container>
                </AppBar>
            </>
        );
    }

    const buttonStylesL = {
        borderRadius: 3,
        backgroundColor: '#2C3E50',
        mr: 2,
        color: '#fff',
        mminWidth: '100px',  // Ensure all buttons have a minimum width
        height: 30, // Altura del botón
        '&:hover': {
            backgroundColor: '#333',
        },
    };

    return (
        <>
            <AppBar position="static">
                <Container maxWidth="100%" sx={{ backgroundColor: 'black' }}>
                    <Toolbar disableGutters>
                        <Link to="/">
                            <Grid container spacing={2} sx={{ flexGrow: 1, flexWrap: 'nowrap', alignItems: 'center' }} gap={2}>
                                <Grid item sx={{
                                    minWidth: 10,
                                    display: 'flex',
                                    justifyContent: 'flex-end', // Alinea el contenido a la derecha
                                    alignItems: 'center' // Centra verticalmente el contenido
                                }}>
                                    <Box
                                        component="img"
                                        src={name}
                                        sx={{
                                            height: '25px',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </Grid>
                                {!user && !isMobile && (
                                    <Grid item>
                                        <Button
                                            component={Link}
                                            to="/how-works"
                                            sx={{ color: 'white', fontSize: '1rem' }}
                                        >
                                            Cómo funciona
                                        </Button>
                                        <Button
                                            component={Link}
                                            to="/question-answer"
                                            sx={{ color: 'white', fontSize: '1rem' }}
                                        >
                                            Preguntas frecuentes
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Link>
                        {!user ? (
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 2 }}>
                                    <Button variant="primary" onClick={() => navigate('/legends')} sx={buttonStylesL}>
                                        <img src={laurelCrownLeft} alt="left laurel" style={{ marginRight: '8px', height: '24px', filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' }} />
                                        Legends
                                        <img src={laurelCrownRight} alt="right laurel" style={{ marginLeft: '8px', height: '24px', filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' }} />
                                    </Button>
                                    <Button
                                        component={Link}
                                        to="/signin"
                                        sx={{
                                            color: 'white',
                                            fontSize: '1rem',
                                            position: 'relative',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                width: '80%', // Ancho del 80%
                                                height: '2px', // Altura de la línea
                                                bottom: '0', // Posición en la parte inferior
                                                left: '10%', // Centrado (80% de ancho)
                                                backgroundColor: 'white', // Color de la línea
                                                transition: 'opacity 0.3s ease', // Transición suave
                                            },
                                            '&::after': {
                                                content: '""',
                                                position: 'absolute',
                                                width: '80%', // Ancho del 80%
                                                height: '2px', // Altura de la línea
                                                bottom: '0', // Posición en la parte inferior
                                                left: '10%', // Centrado (80% de ancho)
                                                backgroundColor: 'transparent', // Oculta la línea
                                                transition: 'background-color 0.3s ease', // Transición suave
                                            },
                                            '&:hover::before': {
                                                backgroundColor: 'transparent', // Elimina la línea al hacer hover
                                            },
                                        }}
                                    >
                                        Iniciar Sesión
                                    </Button>
                                    <Button
                                        component={Link}
                                        to="/request-access"
                                        sx={{
                                            color: 'white',
                                            fontSize: '1rem',
                                            position: 'relative',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                width: '80%', // Ancho del 80%
                                                height: '2px', // Altura de la línea
                                                bottom: '0', // Posición en la parte inferior
                                                left: '10%', // Centrado (80% de ancho)
                                                backgroundColor: 'white', // Color de la línea
                                                transition: 'opacity 0.3s ease', // Transición suave
                                            },
                                            '&::after': {
                                                content: '""',
                                                position: 'absolute',
                                                width: '80%', // Ancho del 80%
                                                height: '2px', // Altura de la línea
                                                bottom: '0', // Posición en la parte inferior
                                                left: '10%', // Centrado (80% de ancho)
                                                backgroundColor: 'transparent', // Oculta la línea
                                                transition: 'background-color 0.5s ease', // Transición suave
                                            },
                                            '&:hover::before': {
                                                backgroundColor: 'transparent', // Elimina la línea al hacer hover
                                            },
                                        }}
                                    >
                                        Solicitar Acceso
                                    </Button>
                                </Box>

                                <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenuOpen}
                                        color="primary"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={handleMenuClose} component={Link} to="/signin">Iniciar Sesión</MenuItem>
                                        <MenuItem onClick={handleMenuClose} component={Link} to="/request-access">Solicitar Acceso</MenuItem>
                                        <MenuItem onClick={handleMenuClose} component={Link} to="/legends">Hall of Legends</MenuItem>
                                        <MenuItem onClick={handleMenuClose} component={Link} to="/how-works">Cómo funciona</MenuItem>
                                        <MenuItem onClick={handleMenuClose} component={Link} to="/question-answer">Preguntas Frecuentes</MenuItem>

                                    </Menu>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                {!isMobile && (
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexGrow: 1, gap: 2, ml: 4 }}>
                                        {user?.userRole !== 'institutional' && (
                                            <Button sx={{ color: 'white', fontSize: '1rem' }} onClick={() => handleSelected('/choose-document')}>+ Antecedentes</Button>
                                        )}
                                        {(user?.userRole !== 'queue' && user?.userRole !== 'institutional') && (
                                            <>
                                                <Button startIcon={<GroupIcon style={{ color: 'white' }} />} sx={{ color: 'white', fontSize: '1rem' }} onClick={() => handleSelected('/directory')}>
                                                    Directorio
                                                </Button>
                                                <Button variant="primary" color="secondary" sx={{ fontSize: '1rem', mminWidth: '100px', height: 30 }} onClick={() => handleSelected('/qualification')}>
                                                    Califícate
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexGrow: 1, gap: 2 }}>
                                    {!isMobile && (
                                        <>
                                            <Grid item>
                                                {(user?.userRole !== 'queue' && user?.userRole !== 'institutional') && (
                                                    <>
                                                        <Button variant="primary" onClick={() => navigate('/legends')} sx={buttonStylesL}>
                                                            <img src={laurelCrownLeft} alt="left laurel" style={{ marginRight: '8px', height: '24px', filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' }} />
                                                            Legends
                                                            <img src={laurelCrownRight} alt="right laurel" style={{ marginLeft: '8px', height: '24px', filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' }} />
                                                        </Button>
                                                        {(!isMobile && user?.userRole !== 'institutional') && (
                                                            <IconButton color="inherit" onClick={handleOpenGovernance}>
                                                                <AccountBalanceIcon sx={{ color: 'white' }} />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleToggleSideMenu} sx={{ p: 0 }}>
                                            {user?.linkPhoto ? (
                                                <Avatar alt="User Photo" src={user?.linkPhoto} />
                                            ) : (
                                                <Avatar alt="User Photo" src={soubidUser} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </>
                        )}
                    </Toolbar>
                    <Governance isVisible={isGovernanceOpen} onClose={handleCloseGovernance} />
                </Container>
            </AppBar>
            <SideMenu open={isSideMenuOpen} onClose={handleToggleSideMenu} />
        </>
    );
}

export default ResponsiveAppBar;
