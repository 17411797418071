import React, { useState, useEffect } from 'react';
import HeaderLegends from './HeaderLegends';
import { Card, CardMedia, CardContent, Typography, Grid, Box, Button, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import publicAxios from '../../apiClients/publicAxios.js';
import LaurelLeft from '../../assets/images/laurel-old-left.png';
import LaurelRight from '../../assets/images/laurel-old-right.png';
import { Link } from 'react-router-dom';
import soubidUser from '../../assets/images/Soubid-user.png';

const headerColor = '#2C3E50';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 300,
  height: 450,
  margin: '1rem',
  position: 'relative',
  backgroundColor: headerColor,
  borderRadius: '10px',
  color: 'white',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  '&:hover .MuiCardMedia-root': {
    transform: 'translateY(-10px)',
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover .card-content': {
    transform: 'translateY(-10px)',
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover .card-description': {
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover .laurels': {
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out',
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme, showDescription }) => ({
  height: showDescription ? '55%' : '60%',
  transition: 'height 0.3s ease-in-out, transform 0.3s ease-in-out',
}));

const CardDescription = styled(Typography)(({ theme }) => ({
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  position: 'absolute',
  bottom: 10,
  width: '100%',
  textAlign: 'center',
  padding: '10px',
  backgroundColor: headerColor,
  height: 'auto', // Asegurar que ocupe el espacio necesario para mostrar todo su contenido
}));

const Title = styled(Typography)(({ theme }) => ({
  color: '#F67147',
  marginBottom: '10px',
  fontWeight: 'bold',
  fontFamily: 'Georgia, serif',
  fontSize: '3rem',
  marginTop: '40px',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: '#6c757d',
  marginBottom: '40px',
  fontSize: '1.5rem',
  fontFamily: 'Arial, sans-serif',
}));

const Container = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: '50px 0',
  textAlign: 'center',
  marginBottom: '50px',
}));

const Laurels = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '30px',
  height: '30px',
  backgroundImage: 'url(/path/to/laurel-icon.png)', // Asegúrate de tener la imagen de los laureles en esta ruta
  backgroundSize: 'cover',
  opacity: 0,
}));

const Legends = () => {
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showDescription, setShowDescription] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchProfiles(1, search, true);
  }, [search]);

  useEffect(() => {
    if (page > 1) {
      fetchProfiles(page, search);
    }
  }, [page]);

  const fetchProfiles = async (page, search, reset = false) => {
    setIsLoading(true);
    try {
      const response = await publicAxios.get('/users/search-legends', {
        params: { search, page },
      });
      const newProfiles = response.data.users.map((profile) => ({
        id: profile.id,
        name: profile.fullName,
        description: profile.description,
        image: profile?.linkPhoto,
        City: profile.City,
        Country: profile.Country,
      }));

      setProfiles((prevProfiles) => reset ? newProfiles : [...prevProfiles, ...newProfiles]);

      // Verificar si hay más perfiles por cargar
      if (newProfiles.length < 20) {
        setHasMore(false);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (searchTerm) => {
    setProfiles([]);
    setPage(1);
    setSearch(searchTerm);
    setHasMore(true);
  };

  const loadMoreProfiles = () => {
    if (!isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleImageError = (event) => {
    event.target.src = soubidUser; // Reemplaza la imagen con la imagen por defecto en caso de error
  };

  const handleMouseEnter = () => {
    setShowDescription(true);
  };

  const handleMouseLeave = () => {
    setShowDescription(false);
  };

  return (
    <div>
      <HeaderLegends onSearch={handleSearch} />
      <Container>
        <Title variant="h4" sx={{ mb: 8 }}>
          Hall of Legends
        </Title>
        <Grid container direction={isMobile ? "column" : "row"} justifyContent="center" spacing={2} sx={{ maxWidth: '100%' }}>
          {isLoading && profiles.length === 0 ? (
            <Typography variant="h6">Cargando perfiles...</Typography>
          ) : profiles.length === 0 ? (
            <Typography variant="h6">No se encontraron perfiles</Typography>
          ) : (
            profiles.map((lawyer, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: '1 0 21%', // Ajustar el tamaño para la pantalla
                  maxWidth: 'calc(25% - 1rem)', // Asegurar el margen entre las tarjetas
                  boxSizing: 'border-box',
                }}
              >
                <StyledCard
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Laurels className="laurels" />
                  <StyledCardMedia
                    component="img"
                    image={lawyer.image || soubidUser} // Usa la imagen por defecto si no hay imagen
                    title={lawyer.name}
                    onError={handleImageError} // Maneja el error de carga de imagen
                    showDescription={showDescription}
                  />
                  <CardContent
                    className="card-content"
                    sx={{
                      textAlign: 'center',
                      height: showDescription ? '35%' : '40%',
                      transition: 'height 0.3s ease-in-out',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        height: '2.5em',
                        lineHeight: '1.2em', // Aumentar la separación entre líneas
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {lawyer.name}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '10px', fontSize: '0.9rem' }}>
                      {lawyer.Country?.name || 'N/A'}
                    </Typography>
                    <Box>
                      <img src={LaurelLeft} alt="Laurel Izquierdo" style={{ width: 20, height: 'auto', marginRight: 5 }} />
                      <img src={LaurelRight} alt="Laurel Derecho" style={{ width: 20, height: 'auto', marginLeft: 5 }} />
                    </Box>
                  </CardContent>
                  <CardDescription className="card-description" variant="body2">
                    <Button variant="primary" component={Link} to={`/profile/${lawyer.id}`}>
                      Ver Perfil
                    </Button>
                  </CardDescription>
                </StyledCard>
              </Grid>
            ))
          )}
        </Grid>
        {hasMore && (
          <Box mt={4}>
            <Button variant="primary" onClick={loadMoreProfiles} disabled={isLoading}>
              {isLoading ? 'Cargando...' : 'Cargar más'}
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default Legends;
