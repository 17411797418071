import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, styled, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../utils/LogoBlack.png';
import { publicAxios } from '../apiClients';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import SelectOption from '../components/sessions/SelectOption';
import { signUp as signUpAws } from 'aws-amplify/auth';
import ConfirmMail from './ConfirmMail';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Loading from '../components/Loading';

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '6px',
    padding: '18.5px 14px', // Ajusta el padding para evitar solapamiento con el label
  },
  '& input::placeholder': {
    color: 'black', // Cambia este color según tus necesidades
    opacity: 0.75, // Asegura que el color se vea claramente
    fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
  },
  '& .MuiFormHelperText-root': {
    color: 'black', // Cambia el color del helper text
  },
  marginTop: '7px',
});

const customTextFieldStyles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '6px',
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    padding: '18.5px 14px', // Ajusta el padding para evitar solapamiento con el label
  },
  '& input::placeholder': {
    color: 'black', // Cambia este color según tus necesidades
    opacity: 0.75, // Asegura que el color se vea claramente
    fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: 'white',
  },
  '& .MuiIconButton-root': {
    padding: 2,
    color: 'black',
  },
  '& .MuiFormHelperText-root': {
    color: 'black', // Cambia el color del helper text
  },
};

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
  '& > :not(style)': { m: theme.spacing(1), width: '100%' },
}));

function RequestAccess() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [professionID, setProfessionID] = useState('');
  const [professions, setProfessions] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [cognitoUserID, setCognitoUserID] = useState('');
  const [countryID, setCountryID] = useState('');
  const [countries, setCountries] = useState([]);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;

    if (password.length < 8) {
      showSnackbar('La contraseña debe tener al menos 8 caracteres.', 'error', 'Error');
      return false;
    }

    if (!hasNumber.test(password)) {
      showSnackbar('La contraseña debe contener al menos un número.', 'error', 'Error');
      return false;
    }
    if (!hasSpecialChar.test(password)) {
      showSnackbar('La contraseña debe contener al menos un carácter especial.', 'error', 'Error');
      return false;
    }
    if (!hasUpperCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra mayúscula.', 'error', 'Error');
      return false;
    }
    if (!hasLowerCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra minúscula.', 'error', 'Error');
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    setIsLoading(true);
    try {
      const professionsResponse = await publicAxios.get('/static-data/professions');
      setProfessions(professionsResponse.data);
      const countriesResponse = await publicAxios.get('/static-data/countries');
      setCountries(countriesResponse.data);
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextStep = () => {
    if (step === 1 && (!firstName || !lastName || !professionID)) {
      setError(true);
      return;
    }
    setError(false);
    setStep(step + 1);
  };

  const handleRequestAccess = async () => {
    setIsLoading(true);
    if (!email || !password || !countryID || !professionID || password !== confirmPassword || !validateEmail(email) || !validatePassword(password)) {
      setError(true);
      setIsLoading(false);
      return;
    }

    const checkEmail = await publicAxios.get(`/back-office/check-email-mvp?email=${email}`);
    if (checkEmail.data.success) {
      if (checkEmail.data.preload) {
        // Perfil pre-creado sin reclamar
        setIsLoading(false);
        showSnackbar('Este correo tiene un perfil pre-creado', 'info');
        navigate(`/exclusive/${checkEmail.data.token}`);
        return;
      } else {
        // Perfil pre-creado reclamado
        showSnackbar('Este correo ya está en uso, por favor ingresa otro.', 'error');
        setIsLoading(false);
        setError(true);
        return;
      }
    }

    try {
      const { userId } = await signUpAws({
        username: email,
        password,
        options: {
          autoSignIn: true
        }
      });
      if (userId) {
        setCognitoUserID(userId);
        setShowConfirm(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message === 'User already exists') {
        showSnackbar('Mail ya existe en la base de datos', 'error');
      } else {
        showSnackbar('Error al solicitar acceso', 'error');
      }
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (showConfirm) {
    const data = {
      firstName,
      lastName,
      email,
      professionID,
      countryID,
      password,
      cognitoUserID,
    };
    return <ConfirmMail userData={data} type="request-access" />;
  }

  return (
    <Box sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
      p: 2,
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 120, width: 120, mb: 4 }} />
      <FormContainer>
        <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: 4 }}>
          Solicitud de Acceso
        </Typography>
        {step === 1 && (
          <>
            <CustomTextField
              placeholder="Nombre"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              error={error && !firstName}
              helperText={error && !firstName ? 'Este campo es obligatorio' : ''}
            />
            <CustomTextField
              placeholder="Apellido"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              error={error && !lastName}
              helperText={error && !lastName ? 'Este campo es obligatorio' : ''}
            />
            <SelectOption
              options={professions}
              selectedOption={professionID}
              onSelectionChange={setProfessionID}
              name="Profesión"
              width="100%"
              error={error && !professionID}
              helperText="Selecciona una profesión"
            />
            <SelectOption
              options={countries}
              selectedOption={countryID}
              onSelectionChange={setCountryID}
              name="País de Origen"
              width="100%"
              error={error && !countryID}
              helperText="Selecciona tu país de origen"
            />
            <Button
              variant="primary"
              fullWidth
              sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
              onClick={handleNextStep}
            >
              Continuar
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <CustomTextField
              placeholder="Correo electrónico (Institucional)"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={error && !email}
              helperText={error && !email ? 'El Correo electrónico es obligatorio' : ''}
              sx={customTextFieldStyles}
            />
            <CustomTextField
              placeholder="Contraseña"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value); // Validar en tiempo real
              }}
              error={error && !password}
              helperText={error && !password ? 'La contraseña es obligatoria' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={customTextFieldStyles}
            />
            <CustomTextField
              placeholder="Confirmar Contraseña"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                validatePassword(e.target.value); // Validar en tiempo real
              }}
              error={error && password !== confirmPassword}
              helperText={error && password !== confirmPassword ? 'Las contraseñas no coinciden' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={customTextFieldStyles}
            />
            <Button
              variant="primary"
              fullWidth
              sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
              onClick={handleRequestAccess}
            >
              Solicitar Acceso
            </Button>
            <Button
              variant="text"
              fullWidth
              sx={{ mt: 2, color: 'white' }}
              onClick={handlePreviousStep}
            >
              Atrás
            </Button>
          </>
        )}
      </FormContainer>
    </Box>
  );
}

export default RequestAccess;
